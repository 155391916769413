import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AccordionItem from "../../components/accordion-item";

import "./connection.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavigateToback from "../../components/button/NavigateToback";
import SocialMediaIcons from "../../components/social-media-icons/SocialMediaIcons";
const Connection = () => {
  const accordionItems = [
    {
      id: 1,
      title: "Maqolani qabul qilish bo‘yicha qanday talablar mavjud ?",
      content: "Maqola IMRAD talablari asosida qabul qilinadi.",
    },
    {
      id: 2,
      title: "Maqola topshirish pullikmi ?",
      content: "Maqolalar bepul qabul qilinadi ?",
    },
    {
      id: 3,
      title: "Maqolani topshirish muddati qachongacha ?",
      content:
        "Jurnalining 2-soni uchun maqolalar 15-fevralga qadar qabul qilinadi.",
    },
    {
      id: 4,
      title:
        "Jurnal OAK ro‘yxatiga kiruvchi jurnallar qatoridan o‘rin olganmi ?",
      content:
        "Yo‘q, hali o‘tkazilmagan, jurnalning 2-sonidan keyin o‘tkazish rejamiz bor.",
    },
    {
      id: 5,
      title: "Jurnalga kimlar maqola berishi mumkin?",

      content:
        "Jurnal barcha uchun ochiq, bu bo‘yicha hech qanday cheklovlar yo‘q.",
    },
    {
      id: 6,
      title:
        "Jurnalning bitta soniga ikki yoki undan ko‘p maqola berish mumkinmi ?",
      content:
        "Bitta son uchun bitta eng dolzarb maqola berish tavsiya etiladi, agar maqolalar ikki va undan ortiq bo‘lsa keyingi sonlarda chiqarilishi mumkin.",
    },
    {
      id: 7,
      title:
        "Agar tahririyat tomonidan maqola nashr uchun tavsiya etilmasa nima qilish mumkin ?",

      content:
        "Taqrizchi tomonidan maqola mazmuni va formati maqul deb topilmasa, muallifga kamchiliklarni tuzatish uchun qayta yuboriladi. Agar maqola belgilangan muddatlarda tahririyatga qayta kelib tushmasa, jurnalning keyingi sonlarida nashr qilishga tavsiya etadi.",
    },
  ];
  return (
    <main>
      <Helmet>
        <title>Biz bilan aloqa</title>
        <meta
          name="description"
          content="Ko'p beriladigan savollar va tahririyat manzili. Maqola qabul qilish talablari, muddatlari va jurnal bilan bog'lanish."
        />
        <meta
          name="keywords"
          content="ko'p beriladigan savollar, maqola qabul qilish, tahririyat manzili, maqola topshirish muddati, jurnal OAK ro'yxati, jurnalga maqola berish, maqola topshirish shartlari, elektron jurnal, ilmiy maqolalar, tahririyat bilan bog'lanish, ilmiy jurnal"
        />
      </Helmet>
      <Container>
        <NavigateToback />
      </Container>
      <section className="connection-section">
        <Container>
          <Row>
            <Col lg={8}>
              {/* FAQ */}
              {/*<h3 className="faq-title title">*/}
              {/*  <svg*/}
              {/*    xmlns="http://www.w3.org/2000/svg"*/}
              {/*    width="10"*/}
              {/*    height="10"*/}
              {/*    viewBox="0 0 10 10"*/}
              {/*    fill="none"*/}
              {/*  >*/}
              {/*    <path*/}
              {/*      fillRule="evenodd"*/}
              {/*      clipRule="evenodd"*/}
              {/*      d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"*/}
              {/*      fill="#0196E3"*/}
              {/*    />{" "}*/}
              {/*  </svg>*/}
              {/*  Ko’p beriladigan savollar*/}
              {/*</h3>*/}
              <div className="w-full mb-7 bg-blue-50 border border-blue-200 shadow-sm rounded-lg overflow-hidden">
                <div className="p-6">
                  <h2 className="text-xl font-bold text-blue-700 mb-4">
                    Tahririyat manzili
                  </h2>
                  <p className="text-blue-600">
                    100143, O‘zbekiston Respublikasi, Toshkent shahri, Chilonzor
                    tumani Bunyodkor shox ko‘chasi, 8/2-uy
                  </p>
                  <div className="mt-6">
                    <h3 className="text-lg font-semibold text-blue-700 mb-2">
                      Tahrir kengashi mas’ul kotibi
                    </h3>
                    <p className="text-blue-600">Oybek Raximberdiyev</p>
                  </div>
                  <div className="mt-4">
                    <h3 className="text-lg font-semibold text-blue-700 mb-2">
                      Telefon
                    </h3>
                    <p className="text-blue-600">+998902155655</p>
                  </div>
                  <div className="mt-4">
                    <h3 className="text-lg font-semibold text-blue-700 mb-2">
                      Pochta
                    </h3>
                    <p className="text-blue-600">nordicejournal@gmail.com</p>
                  </div>
                </div>
              </div>
              {/* Location */}
              <h3 className="faq-title title ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                    fill="#0196E3"
                  />{" "}
                </svg>
                Tahririyat manzili:
              </h3>
              <div className="connection-location">
                <div className="w-full h-0 pb-[56.25%] relative">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23977.31661227081!2d69.21817961753031!3d41.305283213841385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b359ab47501%3A0x618cedae4747d331!2sNordic%20International%20University!5e0!3m2!1sru!2s!4v1723036342570!5m2!1sru!2s"
                    height="450"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className="absolute top-0 left-0 w-full h-full"
                  ></iframe>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <SocialMediaIcons />
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default React.memo(Connection);
