import axios from "axios";
import { useQuery } from "react-query";

export const fetchArticleBySlug = async (slug) => {
  const options = {
    url: `${process.env.REACT_APP_API_URL2}/news?slug=${slug?.queryKey[1]}&lang=${localStorage.getItem("lang") || "en"}`,
    method: "GET",
  };

  const response = await axios.request(options);
  console.log(response);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Error fetching article by slug");
  }
};

const getAllNews = async (lang) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL2}/news/list?page=1&limit=3&lang=${lang.queryKey[1] ? lang.queryKey[1] : "en"}`,
  );

  return response.data.data;
};

export const useFetchArticleBySlug = (slug) => {
  return useQuery(["newsSlug", slug], fetchArticleBySlug);
};

export const useGetAllNewsQuery = (lang) => {
  return useQuery(["allNews", lang], getAllNews);
};
