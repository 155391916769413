/** @format */

import axios from "axios";
import dayjs from "dayjs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router";
import AddArticle from "./addArticle";
import "./profile.css";

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [articlesData, setArticlesData] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");
  const [showAddArticle, setAddShowArticle] = useState(false);
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchProfileAndArticles = async () => {
      try {
        const profileResponse = await axios.get(
          `${process.env.REACT_APP_API_URL2}/author/profile`,
          {
            headers: { authorization: token },
          },
        );
        if (profileResponse.status === 200) {
          setUserData(profileResponse.data.data);
          const articlesResponse = await axios.post(
            `${process.env.REACT_APP_API_URL2}/author/article_author`,
            {
              id: profileResponse.data.data.id,
            },
            {
              headers: { authorization: token },
            },
          );
          if (articlesResponse.status === 200) {
            setArticlesData(articlesResponse.data.data);
            setFilteredArticles(articlesResponse.data.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchProfileAndArticles();
  }, [token, showAddArticle]);

  const handleFilter = (filterType) => {
    if (activeFilter === filterType) {
      setFilteredArticles(articlesData);
      setActiveFilter("");
    } else {
      setActiveFilter(filterType);
      const filtered = articlesData.filter((article) => {
        if (filterType === "ACCEPT") {
          return article.status === "ACCEPT";
        } else if (filterType === "REJECT") {
          return article.status !== "ACCEPT" && article.status !== null;
        }
        return true;
      });
      setFilteredArticles(filtered);
    }
  };

  return (
    <>
      <Container className="profile-container">
        <div className="profile-right">
          <div className="profile-contact">
            <h2 className="profile-contact__title">Profil ma'lumotlari</h2>
            <form className="profile-change__wrapper">
              <label>
                F.I.Sh
                <input type="text" value={userData?.full_name} />
              </label>

              <label>
                Telefon raqam
                <input type="text" value={userData?.phone_number} />
              </label>
              <label>
                Kasbi
                <input type="text" value={userData?.job} />
              </label>
              <label>
                Ish joyi
                <input type="text" value={userData?.place_position} />
              </label>
              <label>
                Ilmiy daraja
                <input type="text" value={userData?.science_degree} />
              </label>
            </form>
          </div>
        </div>
        <div className="profile-journal">
          <div className="profile-journal__top">
            <h2>Yuborilgan maqolalar</h2>

            {userData && (
              <AddArticle
                authorId={userData?.id}
                show={showAddArticle}
                setShow={setAddShowArticle}
              />
            )}
          </div>
          <Table responsive hover>
            <thead className="profile-journal__table-head">
              <tr>
                <th>Sarlavha</th>
                <th className="text-center">Holati</th>
                <th>Kategorisyasi</th>
                <th>Yaratilgan vaqt</th>
                <th>O‘qishlar soni</th>
              </tr>
            </thead>
            <tbody className="profile-tbody">
              {filteredArticles
                .sort((a, b) => a.createdAt - b.createdAt)
                .map((article) => {
                  return (
                    <tr
                      onClick={() => navigate(`/myarticle/${article.slug}`)}
                      key={article.id}
                    >
                      <th>{article.title}</th>
                      <td
                        className={`${
                          article.status === "NEW"
                            ? "text-yellow-300"
                            : article.status === "REVIEW"
                              ? "text-blue-400"
                              : article.status === "PLAGIARISM"
                                ? "text-purple-400"
                                : article.status === "ACCEPT"
                                  ? "text-green-400"
                                  : article.status === "PAYMENT"
                                    ? "text-orange-400"
                                    : "text-red-300"
                        } text-center cursor-pointer`}
                        onClick={() => {
                          navigate(`/articles/article/${article.id}`);
                        }}
                      >
                        {article.status === "NEW" && "🆕 Yangi"}
                        {article.status === "REVIEW" &&
                          "🔍 Taqriz - baholash va tahlil qilish jarayoni"}
                        {article.status === "PLAGIARISM" &&
                          "📊 Antiplagiat - o‘xshashlik darajasini aniqlash"}
                        {article.status === "ACCEPT" && "✅ Qabul qilingan"}
                        {article.status === "PAYMENT" && "💵 To‘lov kutilmoqda"}
                        {article.status !== "NEW" &&
                          article.status !== "REVIEW" &&
                          article.status !== "PLAGIARISM" &&
                          article.status !== "ACCEPT" &&
                          article.status !== "PAYMENT" &&
                          "❌ Rad etilgan"}
                      </td>

                      <th>{article.category.name}</th>
                      <th>{dayjs(article.createdAt).format("DD.MM.YYYY")}</th>
                      <th>{article.viewsCount} ta</th>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </Container>
    </>
  );
};

export default Profile;
