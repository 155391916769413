const fetchArticles = async ({ pageParam = 1 }) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL2}/article?page=${pageParam}&limit=16`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export { fetchArticles };
