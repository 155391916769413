import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Provider from "./context";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/global.css";
import { QueryClient, QueryClientProvider } from "react-query";
import "./i18next";
import dayjs from "dayjs";
import "dayjs/locale/uz-latn";
dayjs.locale("uz-latn");

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>,
);
