import axios from "axios";

const loginRequest = async (data) => {
    try {
        const response = await axios.post("https://journal2.nordicun.uz/author/login", {
            phone_number: `+${data.number}`,
            password: data.password,
        });

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error("Invalid credentials");
        }
    } catch (error) {
        console.error("Login request error:", error);
        throw error;
    }
};

export default loginRequest;
