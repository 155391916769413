import React, { useContext, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import files from "../../assets/file/IMRAD.pdf";
import abstrakt from "../../assets/img/abstrakt.jpg";
import "./instruction.css";
import TgAnimation from "../../components/telegram";
import { Context } from "../../context";
import { Helmet } from "react-helmet";
import NavigateToback from "../../components/button/NavigateToback";

const Instruction = () => {
  const { randomArticle } = useContext(Context);
  const images = useMemo(() => [abstrakt], []);
  return (
    <main className={"connection-main"}>
      <Helmet>
        <title>Qo'llanma</title>
        <meta
          name="instruction"
          content="Saytni ishlatish bo'yicha qo'llanma"
        />
        <meta
          name="keywords"
          content="ilmiy maqola talablari, tadqiqot metodologiyasi qo'llanmasi, tadqiqot ishlari uchun annotatsiya yozish, akademik yozish bo'yicha yo'riqnomalar, taqrizdan o'tgan jurnallarga maqola topshirish, ilmiy maqolalarni yuklab olish, elektron jurnallarga kirish va foydalanish, akademik adabiyotlar sharhi, ilmiy jurnallarda chop etish, tadqiqotchilar uchun ta'lim resurslari"
        />
      </Helmet>
      <Helmet>
        <meta
          name="keywords"
          content="scientific article requirements, research methodology guide, how to write abstracts for research papers, academic writing instructions, peer-reviewed journal submission, download scientific articles, e-journal access and usage, academic literature review, publishing in scientific journals, educational resources for researchers"
        />
      </Helmet>
      <Container>
        <NavigateToback />
      </Container>
      <section className={"connection-section"}>
        <Container>
          <Row>
            <Col className={"connection-left__wrapper"} lg={8}>
              <h2 className={"connection-title title"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                    fill="#0196E3"
                  />
                </svg>
                Ilmiy maqolaga qo‘yilgan talablar
              </h2>
              <div className={"connection-left__about"}>
                <div className="connection-list__wrapper">
                  <Row className="top-article__list">
                    <Col lg={6}>
                      {/* 1 */}
                      <Col lg={12} className="top-article__item">
                        <h4>1. ABSTRAKT (ANNOTATSIYA)</h4>
                        <ul>
                          <li>- Muammoning qo‘yilishi;</li>
                          <li>- Metodologiyani aniqlash;</li>
                          <li>- Asosiy topilmalar;</li>
                          <li>- Umumiy xulosa.</li>
                        </ul>
                      </Col>
                      {/* 2 */}
                      <Col lg={12} className="top-article__item">
                        <h4>2. KIRISH</h4>
                        <ul>
                          <li>
                            - Tadqiqot ob'yektini belgilab olish (markaziy
                            tushunchalarni mujassamlashtirish, mavzuni
                            umumlashtirish, oldingi tadqiqot ob'yektlarini
                            ko‘rib chiqish);
                          </li>
                          <li>
                            - Muammoning markazga qo‘yilishi (mavzuga oid qarshi
                            fikrlarni o‘rganish, masaladagi mavjud kamchilik va
                            bo‘shliqlarni ko‘rsatish, an’anaviy xulosalarni
                            tahlil qilish);
                          </li>
                          <li>
                            - Tadqiqotning borish jarayoni (maqsadlarni
                            tavsiflash, ayni vaqtdagi tadqiqot ishlarini e’lon
                            qilish, asosiy ilmiy yangilik va topilmalarni hamda
                            jarayonni tavsiflovchi natijalarni ta’kidlash.
                          </li>
                          <li>- ADABIYOTLAR SHARXI</li>
                        </ul>
                      </Col>
                      {/* 3 */}
                      <Col lg={12} className="top-article__item">
                        <h4>3. METODOLOGIYA</h4>
                        <ul>
                          <li>
                            - Ishonchli ma’lumotlarni yig‘ish jarayoni va
                            ma’lumotlarni tahlil qilish metodikasi.
                          </li>
                        </ul>
                      </Col>
                    </Col>
                    <Col lg={6}>
                      {/* 4 */}
                      <Col lg={12} className="top-article__item">
                        <h4>4. TADQIQOT NATIJASI</h4>
                        <ul>
                          <li>
                            - Olingan natijalarni izohlash va uning ilmiy
                            ahamiyatini ochib berish.{" "}
                          </li>
                        </ul>
                      </Col>
                      {/* 5 */}
                      <Col lg={12} className="top-article__item">
                        <h4>5. DISKUSSIYA</h4>
                        <ul>
                          <li>
                            - Kirish (ilmiy topilmalarni muhokama qilish va
                            ularning ishonchliligini isbotlash ).
                          </li>
                          <li>
                            - Baholash (tahlil qilish, tushunchalarning ma’noviy
                            tasnifi, adabiyotlarga havola, qo‘yilgan gipotezalar
                            bo‘yicha xulosalar;
                          </li>
                          <li>
                            - Xulosa (tadqiqotning cheklanganligi (qamrov
                            doirasi), o‘rganilmagan jihatlar bo‘yicha
                            tavsiyalar).
                          </li>
                        </ul>
                      </Col>
                      {/* 6 */}
                      <Col lg={12} className="top-article__item">
                        <h4>6. XULOSA</h4>
                        <ul>
                          <li>
                            - Xulosa aniq va qisqa, muammoning yechimiga
                            qaratilgan va aniq takliflarga ega bo‘lishi lozim.{" "}
                          </li>
                        </ul>
                      </Col>
                      {/* 7 */}
                      <Col lg={12} className="top-article__item">
                        <h4>7. TASHAKKURNOMA</h4>
                        <ul>
                          <li>
                            - Tadqiqot davomida muallifga yordam bergan olimlar,
                            hammualliflar, tadqiqot o‘tkazilgan tashkilot va h.k
                            larga muallifning tashakkurnomasi.
                          </li>
                        </ul>
                      </Col>
                      {/* 8 */}
                      <Col lg={12} className="top-article__item">
                        <h4>8. ADABIYOTLAR RO‘YXATI</h4>
                        <ul>
                          <li>
                            - Foydalanilgan adabiyotlar ro‘yxati Mendeley,
                            EndNote va shu kabi dasturiy ta'minotlarda yuritish
                            tavsiya etiladi.
                          </li>
                        </ul>
                      </Col>
                    </Col>
                  </Row>
                  <div className={"download-article__wrapper"}>
                    <Link
                      to={files}
                      className={"connection-download button"}
                      target="_blank"
                    >
                      To'liq o'qish uchun yuklash
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col className={"connection-aside__wrapper"} lg={4}>
              {randomArticle && (
                <div>
                  <img src={images[0]} className="pt-12" alt="rasm" />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <footer className={"connection-footer"}>
        <Footer />
      </footer>
      <TgAnimation />
    </main>
  );
};

export default React.memo(Instruction);
