/** @format */

import moment from "moment";
import { Link } from "react-router-dom";
import "./cards.css";
import React from "react";
import { HiEye } from "react-icons/hi";

const Cards = ({
  type,
  title,
  width,
  height,
  id,
  time,
  image,
  category,
  anim,
  views,
  author,
}) => {
  switch (type) {
    case "article-big":
      return (
        <>
          <Link to={`/articles/${id}`} className={``}>
            <div className="big-article__row">
              <div className="big-article__img">
                {image ? (
                  <img src={image} alt="Maqola rasmi" />
                ) : (
                  <img
                    src="https://picsum.photos/50/50"
                    loading="lazy"
                    alt="Maqola rasmi"
                  />
                )}
              </div>

              <div className=" flex flex-col justify-between">
                <div className="flex items-center justify-content-between">
                  <span className="big-article__category">{category}</span>
                  <div className="flex items-center gap-1">
                    <HiEye className="text-gray-400 text-sm" />
                    <p className="text-gray-400 text-sm">{views}</p>
                  </div>
                </div>
                <div>
                  <h3 className="max-sm:text-sm big-article__title mb-5">
                    <strong>{title}</strong>
                  </h3>
                  <div className={`big-article__type`}>
                    <span className="big-article__author text-[11px]">
                      {author}
                    </span>
                    <span className="big-article__time text-[10px]">
                      <i className="ri-calendar-2-line"></i>
                      {moment(time).utc().format("YYYY-MM-DD")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </>
      );

    case "article-small":
      return (
        <>
          <Link to={`/articles/${id}`} className={``}>
            <div className="small-article__row">
              <div className="small-article__img max-sm:max-w-max">
                {image ? (
                  <img
                    src={image}
                    className="h-full"
                    alt="Maqola rasmi"
                    loading="lazy"
                  />
                ) : (
                  <img
                    src="https://picsum.photos/50/50"
                    loading="lazy"
                    alt="Maqola rasmi"
                  />
                )}
              </div>

              <div className="small-article__about">
                <div
                  className={`small-article__type max-sm:flex-col-reverse max-sm:items-start`}
                >
                  <span className="small-article__time">
                    <i className="ri-calendar-2-line"></i>
                    {moment(time).utc().format("YYYY-MM-DD")}
                  </span>
                  <span className="small-article__category max-sm:m-0 max-sm:mb-1.5 max-sm:py-0.5">
                    {category}
                  </span>
                </div>
                <h2 className="small-article__title">{title}</h2>
                <div className="flex items-center justify-between">
                  <span className="small-article__author">{author}</span>
                  <div className="flex items-center gap-1">
                    <HiEye className="text-gray-400 text-sm" />
                    <p className="text-gray-400 text-sm">{views}</p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </>
      );

    case "news":
      return (
        <>
          <Link to={`/articles/${id}`} className={`news-card`}>
            <div className="news-card__row p-3">
              <div className="news-card__img">
                {image ? (
                  <img
                    src={image}
                    className="h-full object-cover"
                    alt="Maqola rasmi"
                    loading="lazy"
                  />
                ) : (
                  <img
                    src="https://picsum.photos/50/50"
                    loading="lazy"
                    alt="Maqola rasmi"
                  />
                )}
              </div>

              <div className="news-card__about py-1">
                <span className="news-card__time">
                  <i
                    className="ri-calendar-2-line"
                    style={{ marginRight: "5px" }}
                  ></i>

                  {moment(time).utc().format("DD-MM-YYYY")}
                </span>
                <h2 className="news-card__title">{title}</h2>
                <div className="flex items-center justify-content-between">
                  <span className="news-card__more">Batafsil...</span>
                  <div className="flex items-center gap-1">
                    <HiEye className="text-gray-400 text-sm" />
                    <p className="text-gray-400 text-sm">{views}</p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </>
      );

    case "card-social":
      return (
        <>
          <div className="big-article">
            <Link className={`big-article__row ${anim}`} to={`/articles/${id}`}>
              <div className="big-article__img">
                {image ? (
                  <img
                    loading="lazy"
                    src={image}
                    style={{ width: `${width}`, height: `${height}` }}
                    alt="Img"
                  />
                ) : (
                  <img
                    loading="lazy"
                    src="https://picsum.photos/100px/100px"
                    style={{ width: `${width}`, height: `${height}` }}
                    alt="Img"
                  />
                )}
              </div>
              <div className="big-article__about">
                <span className="big-article__category">{category}</span>
                <h2 className="big-article__title">{title}</h2>
                <span className="big-article__time">
                  <i className="ri-calendar-2-line"></i>
                  {moment(time).utc().format("YYYY-MM-DD")}
                </span>
              </div>
            </Link>
          </div>
        </>
      );
    case "big-news":
      return (
        <>
          <Link to={`/news/${id}`} className={`news-card`}>
            <div className="news-card__row">
              <div className="news-card__img">
                {image ? (
                  <img
                    src={image}
                    className="h-full object-cover"
                    alt="Maqola rasmi"
                    loading="lazy"
                  />
                ) : (
                  <img
                    src="https://picsum.photos/50/50"
                    loading="lazy"
                    alt="Maqola rasmi"
                  />
                )}
              </div>

              <div className="news-card__about py-2">
                <span className="news-card__time pb-2">
                  <i
                    className="ri-calendar-2-line"
                    style={{ marginRight: "5px" }}
                  ></i>

                  {moment(time).utc().format("YYYY-MM-DD")}
                </span>
                <h2 className="news-card__title pb-4">{title}</h2>
                <div className="flex items-center justify-content-between">
                  <span className="news-card__more text-[#0f3260]">
                    Batafsil...
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </>
      );
    default:
      return null;
  }
};

export default React.memo(Cards);
