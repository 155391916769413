/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, Col, Container, Row } from "react-bootstrap";

// Components
import Footer from "../../components/footer";
import Cards from "../../components/cards";
// CSS
import "./archive.css";
import axios from "axios";
import { Helmet } from "react-helmet";

const VolumeArticles = () => {
  const { volumeID } = useParams();
  const [data, setData] = useState([]);
  const [volume, setVolume] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL2}/article/user/volume/${volumeID}`,
        requestOptions,
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const newData = await response.json();

      setData(newData);
    } catch (error) {}
  };

  const getVolumeInfo = async () => {
    const options = {
      url: `${process.env.REACT_APP_API_URL2}/volume/${volumeID}`,
      method: "GET",
    };

    try {
      const response = await axios.request(options);
      if (response.status === 200) {
        setVolume(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
    getVolumeInfo();
  }, [volumeID]);

  const handleDownload = () => {
    if (volume && volume.source && volume.source.file_path) {
      window.open(
        `${process.env.REACT_APP_API_URL2}${volume.source.file_path}`,
        "_blank",
      );
    }
  };

  return (
    <>
      <Helmet>
        <title> {`${volume?.description}`} - Ilmiy maqolalar to'plami</title>
        <meta
          name="description"
          content="Ilmiy maqolalar to'plami: hajmi, mualliflar, kategoriyalar va yuklab olish imkoniyatlari."
        />
        <meta
          name="keywords"
          content="ilmiy maqolalar to'plami, ilmiy maqola hajmi, maqola mualliflari, maqola kategoriyalari, maqolalarni yuklab olish, ilmiy tadqiqotlar, elektron jurnal, ilmiy nashrlar, tadqiqot maqolalari, akademik maqolalar, jurnal maqolalari, ilmiy izlanishlar"
        />
      </Helmet>

      <main style={{ minHeight: "calc(100vh - 102px)" }}>
        <section className="archive-section">
          {data && (
            <Container>
              <h2 className="archive-title title pl-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                    fill="#0196E3"
                  />
                </svg>
                {data[0]?.volume?.title} ning maqolalari.
                {volume && (
                  <Button
                    style={{ marginLeft: "10px" }}
                    onClick={handleDownload}
                  >
                    Yuklab olish
                  </Button>
                )}
              </h2>
              <Row>
                {data?.map((item) => (
                  <Col className="archive-card" lg={4} xl={3} key={item.id}>
                    <Cards
                      type="article-big"
                      title={item.title}
                      views={item.viewsCount}
                      keywords={item.abstract}
                      category={item.category.name}
                      image={`${process.env.REACT_APP_API_URL2}${item.image.file_path}`}
                      width="306px"
                      height="350px"
                      id={item.slug}
                      time={item.createdAt}
                      author={item.author.full_name}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
};

export default React.memo(VolumeArticles);
