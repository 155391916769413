import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import AccordionItem from "../../components/accordion-item/index";
import Footer from "../../components/footer";
import sherzod from "../../assets/img/Sherzod Mustafakulov.jpg";
import oybek from "../../assets/img/Oybek Raximberdiyev.jpg";
import odil from "../../assets/img/Odil Qoysinov.jpg";
import file from "../../assets/file/nordic2.pdf";
import "./about-journal.css";
import TgAnimation from "../../components/telegram";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";
import SocialMediaIcons from "../../components/social-media-icons/SocialMediaIcons";
import { accordionItems, scholars } from "./about.query";

export const AboutJournal = () => {
  return (
    <main className={"connection-main"}>
      <Helmet>
        <title>Journal haqida</title>
        <meta name="about-journal" content="e-journal haqida" />
        <meta
          name="keywords"
          content="e-journal-about, about, haqida,journal-haqida,ilmiy-amaliy elektron jurnali,Tahririyat hayati,Tahririyat a'zolari"
        />
      </Helmet>
      {/* Header */}
      <header className={"about-header"}></header>
      {/* Section Connection */}
      <section className={"connection-section"}>
        <Container>
          <Row>
            <Col className={"connection-left__wrapper"} lg={8}>
              <div className={"connection-left__about"}>
                <h2 className={"about-title title max-sm:block"}>
                  <strong>“Nordic” </strong>
                  ilmiy-amaliy elektron jurnali
                </h2>
                <p className="about-subtitle">
                  Xalqaro Nordik universiteti Oʻzbekiston Respublikasi
                  Prezidenti Administratsiyasi huzuridagi Axborot va ommaviy
                  kommunikatsiyalar agentligining{" "}
                  <span className="about-bold">
                    №057211 raqamli Guvohnomasi
                  </span>
                  asosida <span className="about-bold">“Nordic”</span>{" "}
                  ilmiy-amaliy elektron jurnalini nashr etish imkoniyatiga ega
                  bo‘ldi. Jurnalda maqolalar o‘zbek, rus, ingliz va fin
                  tillarida nashr etiladi. <br /> <br />
                  <p className="about-subtitle about-subtitle__date">
                    Nordik ilmiy-amaliy elektron jurnali davriy nashrlarini{" "}
                    <span className="about-bold">
                      ISSN (Seriyali nashrlarning xalqaro standart raqamlanishi)
                    </span>{" "}
                    tartib raqami orqali davlatlataro standart talablari asosida
                    bo‘lishini to‘liq ta’minlash maqsadida{" "}
                    <Link
                      to={"https://portal.issn.org/resource/ISSN/3030-3400"}
                    >
                      ISSN-3030-3400
                    </Link>{" "}
                    raqami bilan faoliyat olib boradi.
                  </p>
                  <p className="about-subtitle about-subtitle__date">
                    <span className="about-bold">
                      Jurnalning nashr etilish sanasi:
                    </span>{" "}
                    har ikkinchi oyning 15-sanasi.
                  </p>
                  <p className="about-subtitle about-subtitle__date">
                    <span className="about-bold">Jurnalning davriyligi:</span>{" "}
                    bir yilda 5 ta son
                  </p>
                  <p className="about-subtitle">
                    <span className="about-bold">Muassis:</span> Xalqaro Nordik
                    universiteti
                  </p>
                  <span className="about-bold">
                    Jurnal faoliyatining asosiy maqsadi:
                  </span>{" "}
                  professor-о‘qituvchilar va ilmiy izlanuvchilar, magistrlar va
                  talabalar tomonidan olib borilayotgan ilmiy izlanishlar,
                  ilmiy-tadqiqot natijalarini elektron tarzda chop etish va
                  ommaviy e’lon qilib borishdan iborat. Shuningdek, zamonaviy
                  ilg‘or qarashlar bilan tanishtirish, ilmiy faoliyatlarini
                  jonlantirish, universitet ilmiy nufuzi va salohiyatini
                  o‘stirish, turli innovatsion tadqiqotlarda ishtirok etishini
                  kuchaytirish, tadqiqotlarning ilmiy-amaliy ahamiyatini yanada
                  oshirish asosiy maqsadimizdir.
                </p>
                <ul className="about-list__first">
                  <h3 className="mobile-humans__title title">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                        fill="#0196E3"
                      />
                    </svg>
                    Tahririyat hayati
                  </h3>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={5}
                    loop={true}
                    lazy={true}
                    speed={1500}
                    autoplay={{
                      delay: 1500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    breakpoints={{
                      640: {},
                      768: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 5,
                      },
                    }}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                    className=" max-sm:block"
                  >
                    <SwiperSlide className="text-center justify-center items-center w-[185px] max-sm:w-full  ">
                      <div className=" relative  text-center w-full h-[205px] max-sm:w-full max-sm:h-[400px] ">
                        <img
                          src={sherzod}
                          alt="Tahririyat hayati rasm"
                          className="w-full h-full"
                        />
                        <div className="absolute px-1 bottom-0 left-0 right-0 bg-[rgba(1,150,227,0.6)] bg-opacity-50 text-white w-full h-[55px] flex flex-col justify-center items-center ">
                          <p className="text-xs font-semibold ">
                            Sherzod Mustafakulov
                          </p>
                          <p className="text-white text-[10px]">
                            {" "}
                            Tahririyat kengashi raisi: iqtisodiyot fanlari
                            doktori, professor
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="relative  text-center w-[205px] max-sm:w-full  ">
                      <div className="relative text-center w-full h-[205px] max-sm:w-full max-sm:h-[400px]">
                        <img
                          src={oybek}
                          alt="Tahririyat hayati rasm"
                          className="w-full h-full"
                        />
                        <div className="absolute bottom-0 left-0 right-0 bg-[rgba(1,150,227,0.6)] bg-opacity-50 text-white w-full h-[55px] flex flex-col justify-center items-center ">
                          <p className="text-xs font-semibold">
                            Oybek Raximberdiyev
                          </p>
                          <p className="text-xs">
                            Tahririyat kengashi mas’ul kotibir
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="relative  text-center w-[205px] max-sm:w-full  ">
                      <div className="relative  text-center w-full h-[205px] max-sm:w-full max-sm:h-[400px]">
                        <img
                          src={odil}
                          alt="Tahririyat hayati rasm"
                          className="w-full h-full"
                        />
                        <div className="absolute bottom-0 left-0 right-0 bg-[rgba(1,150,227,0.6)] bg-opacity-50 text-white w-full h-[55px] flex flex-col justify-center items-center ">
                          <p className="text-xs font-semibold">
                            Odil Qo’ysinov
                          </p>
                          <p className="text-xs text-white">
                            Bosh muharrir: pedagogika fanlari doktori, professor
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="  text-center w-[205px] max-sm:w-full ">
                      <div className="relative  text-center w-full h-[205px] max-sm:w-full max-sm:h-[400px] ">
                        <img
                          src={require("../../assets/img/azizbek abdullayev.jpg")}
                          alt="Tahririyat hayati rasm"
                          className="w-full h-full"
                        />
                        <div className="absolute bottom-0 left-0 right-0 bg-[rgba(1,150,227,0.6)] bg-opacity-50 text-white w-full h-[55px] flex flex-col justify-center items-center">
                          <p className="text-xs font-semibold">
                            Azizbek Abdullayev
                          </p>
                          <p className="text-xs">Dizayner</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </ul>
                <ul className="about-list__second">
                  <li className="about-list__item about-list__item-first">
                    <h3 className="about-list__second-title title">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                          fill="#0196E3"
                        />
                      </svg>
                      Tahririyat a'zolari:
                    </h3>
                  </li>
                  {scholars.map((item, index) => (
                    <li className="about-list__item">
                      <span className="about-bold">{item.name}</span>-
                      {item.title} {item.country}
                    </li>
                  ))}
                </ul>
                <ul className="location-wrapper">
                  <li className="location-item about-bold">
                    Tahririyat manzili:
                  </li>
                  <li className="location-item">
                    100143, O‘zbekiston Respublikasi, Toshkent shahri, Chilonzor
                    tumani Bunyodkor shox ko‘chasi, 8/2-uy
                  </li>
                </ul>
                <div className="image-wrapper">
                  <picture>
                    <source
                      media="(max-width: 500px)"
                      srcSet={require("../../assets/img/about-journal-mobile.jpg")}
                    />
                    <img
                      alt="journal-desktop-image"
                      src={require("../../assets/img/about-journal-desktop.jpg")}
                    />
                  </picture>
                  <Link
                    to={file}
                    target="_blank"
                    className="sertification-download "
                  >
                    <button className="button">YUKLAB OLISH</button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col className={"connection-aside__wrapper"} lg={4}>
              {/* FAQ */}
              <h3 className="faq-title title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                    fill="#0196E3"
                  />{" "}
                </svg>
                Ko’p beriladigan savollar
              </h3>
              <div className="accordion-list">
                {accordionItems.map((item, index) => (
                  <AccordionItem
                    key={item.id}
                    title={item.title}
                    content={item.content}
                  />
                ))}
              </div>
              <h3 className="faq-title title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                    fill="#0196E3"
                  />{" "}
                </svg>
                Tahririyat manzili:
              </h3>
              <div className="about-location">
                <iframe
                  src="https://yandex.uz/map-widget/v1/?ll=69.292550%2C41.299236&mode=search&oid=96786672189&ol=biz&z=11.43"
                  width="100%"
                  height="250px"
                  frameBorder="1"
                  loading="lazy"
                  allowFullScreen={true}
                ></iframe>
              </div>
              {/* Social */}
              <SocialMediaIcons />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Footer */}
      <footer className={"connection-footer"}>
        <Footer />
      </footer>
      <TgAnimation />
    </main>
  );
};

export default React.memo(AboutJournal);
