import React, { memo, useEffect, useState } from "react";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/home/home";
import Publications from "./pages/publications";
import Category from "./pages/category";
import AboutJournal from "./pages/about-journal";
import Archive from "./pages/archive";
import Instruction from "./pages/instruction";
import Connection from "./pages/connection";
import Navbar from "./components/navbar";
import Profile from "./pages/profile";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import VolumeArticles from "./pages/volume-articles";
import CategoryArticles from "./pages/category-articles";
import SingleUser from "./pages/single_user/singleUser";
import HomeArticle from "./components/home-article";
import HomeNews from "./components/home-news";
import Page404 from "./pages/404Page/Page404";
import SearchArticle from "./pages/Search-page/Search";
import SingelNews from "./pages/single news/SingleNews";
import MyArticle from "./pages/myarticle/MyArticle";
import { Container } from "react-bootstrap";
import Articles from "./pages/article/singleArticle";

function App() {
  const [screen, setScreen] = useState(window.innerWidth);
  const navigate = useNavigate();

  const mobileMenu = [
    {
      url: "/",
      name: "Bosh sahifa",
      icon: "ri-home-4-line",
    },
    {
      url: "/article",
      name: "Maqolalar",
      icon: "ri-article-line",
    },
    {
      url: "/last-articles",
      name: "So'ngilari",
      icon: "ri-news-line",
    },
    {
      url: "/search-article",
      name: "Izlash",
      icon: "ri-search-line",
    },
  ];

  useEffect(() => {
    const handleResize = (size) => {
      setScreen(size.currentTarget.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (screen >= 450) {
      const currentPath = window.location.pathname;
      if (currentPath === "/article" || currentPath === "/last-articles") {
        navigate("/");
      }
    }
  }, [screen, navigate]);

  return (
    <>
      <ToastContainer />
      <Navbar />
      <div className="max-sm:mb-20 pb-10">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/articles" element={<Archive />} />
          <Route path="/volume/:volumeID" element={<VolumeArticles />} />
          <Route path="/news/:slug" element={<SingelNews />} />
          <Route path="/category/:categoryID" element={<CategoryArticles />} />
          <Route path="/about-journal" element={<AboutJournal />} />
          <Route path="/publications" element={<Publications />} />
          <Route path="/category" element={<Category />} />
          <Route
            path="/billing"
            element={
              <Container>
                <div className="w-full">
                  <iframe
                    className="w-full h-screen"
                    src="https://my.click.uz/services/pay?service_id=35556&merchant_id=26641&amount=1000&transaction_param=Test_001001&return_url=journal.nordicuniversity.org&card_type=UZCARD"
                  />
                </div>
              </Container>
            }
          />
          <Route path="/articles/:slug" element={<Articles />} />
          <Route path="/instruction" element={<Instruction />} />
          <Route path="/connection" element={<Connection />} />
          <Route
            path="/profile"
            element={
              localStorage.getItem("accessToken") ? (
                <Profile />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route path="/user/:userID" element={<SingleUser />} />
          <Route path="/article" element={<HomeArticle />} />
          <Route path="/last-articles" element={<HomeNews />} />
          <Route path="/search-article" element={<SearchArticle />} />
          <Route path="/myarticle/:slug" element={<MyArticle />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </div>
      <section className="mobile-navigate">
        <div className="mobile-navigate__wrapper">
          {mobileMenu.map((item, index) => (
            <NavLink
              to={item.url}
              key={index}
              className={({ isActive }) =>
                isActive
                  ? "mobile-navigate__item mobile-navigate__item-active"
                  : "mobile-navigate__item"
              }
            >
              <button>
                <i className={item.icon}></i> <strong>{item.name}</strong>
              </button>
            </NavLink>
          ))}
        </div>
      </section>
    </>
  );
}

export default memo(App);
