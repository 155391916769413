/** @format */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useMutation } from "react-query";
import { registerUser } from "./query.register";
import "./register.css";
import PhoneInput from "react-phone-input-2";

const Register = ({ close }) => {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [number, setNumber] = useState(0);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const mutation = useMutation(registerUser, {
    onSuccess: (data) => {
      localStorage.setItem("accessToken", data?.data?.token);
      navigate("/profile");
      close();
    },
    onError: (error) => {
      setMessage("Xatolik, qaytdan urinib ko‘ring!");
      console.error(error);
    },
  });

  const onSubmit = (data) => {
    if (number?.length <= 6) {
      setMessage("Telefon raqam to‘liq kiritilmadi!");
      return;
    }
    mutation.mutate(data);
  };

  return (
    <>
      <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
        {/* Number */}
        <label>
          Telefon raqam
          <PhoneInput
            inputClass="w-full py-5"
            country={"uz"}
            value="+998"
            onChange={(phone) => {
              setValue("number", phone);
              setNumber(phone);
            }}
            inputProps={{
              name: "text",
              required: true,
              autoFocus: true,
            }}
            enableSearch={false}
            disableDropdown={false}
            disableCountryCode={false}
          />
        </label>
        {/* Password */}
        <label>
          Parol
          <input
            required={true}
            {...register("password")}
            name="password"
            type="password"
            placeholder="Parol kiriting"
          />
        </label>
        {/* FullName */}
        <label>
          To‘liq ism, familiya
          <input
            required={true}
            {...register("full_name")}
            name="full_name"
            type="text"
            placeholder="F.I.Sh kiriting"
          />
        </label>
        {/* science_degree */}
        <label>
          Ilmiy daraja va unvoningiz
          <input
            required={true}
            {...register("science_degree")}
            name="science_degree"
            type="text"
            placeholder="Ilmiy daraja va unvoningiz kiriting"
          />
        </label>
        {/* Birthday */}

        {/* Place Position */}
        <label>
          Ish joyi
          <input
            required={true}
            {...register("place_position")}
            name="place_position"
            type="text"
            placeholder="Ish joyini kiriting"
          />
        </label>
        {/* Job */}
        <label>
          Kasb
          <input
            required={true}
            {...register("job")}
            name="job"
            type="text"
            placeholder="Kasbingizni kiriting"
          />
        </label>
        {errors.exampleRequired && <span>Majburiy maydon</span>}
        <span>{message}</span>
        <button
          className="register-submit"
          type="submit"
          disabled={mutation.isLoading}
        >
          {mutation.isLoading ? "Yuklanmoqda..." : "Ro‘yxatdan o‘tish"}
        </button>
      </form>
    </>
  );
};

export default Register;
