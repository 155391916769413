import { useNavigate, useParams } from "react-router";
import no_image from "../../../src/assets/img/1699046065_pibig-info-p-golova-na-belom-fone-vkontakte-1.png";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import Footer from "../../components/footer";
import "./article.css";
import { Helmet } from "react-helmet";
import NavigateToback from "../../components/button/NavigateToback";
import { useFetchArticleBySlug, useGetAllNewsQuery } from "./query.article";
import { memo, useEffect } from "react";
import Cards from "../../components/cards";

const SingelNews = () => {
  const { slug } = useParams();
  const { data } = useFetchArticleBySlug(slug);
  const lang = localStorage.getItem("lang");
  const { data: news } = useGetAllNewsQuery(lang);

  function formatDate(ISOString) {
    const date = new Date(ISOString);
    const year = date.getFullYear();
    const day = date.getDate();
    const monthNumber = date.getMonth();
    let monthName;
    switch (monthNumber) {
      case 0:
        monthName = "Yanvar";
        break;
      case 1:
        monthName = "Fevral";
        break;
      case 2:
        monthName = "Mart";
        break;
      case 3:
        monthName = "Aprel";
        break;
      case 4:
        monthName = "May";
        break;
      case 5:
        monthName = "Iyun";
        break;
      case 6:
        monthName = "Iyul";
        break;
      case 7:
        monthName = "Avgust";
        break;
      case 8:
        monthName = "Sentabr";
        break;
      case 9:
        monthName = "Oktyabr";
        break;
      case 10:
        monthName = "Noyabr";
        break;
      case 11:
        monthName = "Dekabr";
        break;
      default:
        monthName = "Noma'lum";
        break;
    }
    return `${monthName} ${day}, ${year}`;
  }

  useEffect(() => {
    window.scroll({ top: 0, behavior: "auto" });
  }, []);

  return (
    <>
      <Helmet>
        <title>{data ? data.title : "Maqola sarlavhasi"}</title>
        <meta
          name="description"
          content={data ? data.description : "yangiligi."}
        />
        <meta
          name="keywords"
          content={data ? data.hashTags : "yangilik kali8t so'zlari"}
        />
        <meta
          property="og:title"
          content={data ? data.title : "yangilik sarlavhasi"}
        />
        <meta
          property="og:description"
          content={
            data ? data.description : "Ilmiy maqola haqida qisqacha ma'lumot."
          }
        />
        <meta
          property="og:url"
          content={`https://journal.nordicuniversity.org/articles/${slug}`}
        />
        <meta
          property="og:image"
          content={
            data
              ? `${process.env.REACT_APP_API_URL2}${data?.image?.file_path}`
              : no_image
          }
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={data ? data.title : "Maqola sarlavhasi"}
        />
        <meta
          name="twitter:description"
          content={
            data ? data.description : "Ilmiy maqola haqida qisqacha ma'lumot."
          }
        />
        <meta
          name="twitter:image"
          content={
            data
              ? `${process.env.REACT_APP_API_URL2}${data?.source?.file_path}`
              : no_image
          }
        />
      </Helmet>
      <main className="article-main max-sm:mb-20 mt-10">
        <>
          <section className="main-font">
            <div className="news_container">
              <NavigateToback />
              <h2 className="text-3xl pb-3 max-sm:text-xl font-semibold">
                {data ? data.title : <Skeleton width={300} />}
              </h2>
              {data ? (
                <img
                  className="w-full py-3 h-[700px] pb-5 object-cover max-sm:object-contain max-sm:h-auto"
                  src={process.env.REACT_APP_API_URL2 + data.source.file_path}
                  alt=""
                />
              ) : (
                <Skeleton height={300} />
              )}
              <div className="flex flex-wrap items-start gap-5">
                <div className="text-block">
                  <p className="text-xl text-justify indent-10">
                    {data ? data.description : <Skeleton count={3} />}
                  </p>
                  <hr className="my-3" />
                  <p className="text-xl text-justify indent-10">
                    {data ? data.body : <Skeleton count={5} />}
                  </p>
                </div>
              </div>

              <ul className="flex items-center flex-wrap mt-4 mb-7 gap-2">
                {data ? (
                  data.hashtags.split(",").map((item, index) => (
                    <li
                      className="bg-[#f0f0f0] hover:bg-transparent border transition-all border-[#f8f8f8] text-black px-2 py-1 rounded-md"
                      key={index}
                    >
                      {item}
                    </li>
                  ))
                ) : (
                  <Skeleton count={5} width={100} />
                )}
              </ul>
            </div>
          </section>
        </>
        <footer className="article-footer">
          <Footer />
        </footer>
      </main>
    </>
  );
};

export default memo(SingelNews);
