import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { HomeOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Input,
  Select,
  Row,
  Col,
  Skeleton,
  Breadcrumb,
} from "antd";
import { initialState } from "./search.states";
import { useMutation, useQuery } from "react-query";
import { fetchVolumes } from "../publications/querry.publication";
import {
  volumeToValueAndLabel,
  getAuthor,
  getSubCategoriesByCategoryId,
  getAllFilteredArticles,
} from "./search.queryies";
import { fetchCategories } from "../category/query.category";
import dayjs from "dayjs";
import { BiSort, BiTrash } from "react-icons/bi";
import moment from "moment";
import { Link } from "react-router-dom";
import { HiEye } from "react-icons/hi";
import { GrArticle } from "react-icons/gr";
import { BsSearch } from "react-icons/bs";

const SearchArticle = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filterState, setFilterState] = useState(initialState);
  const checkBoxDoi = useRef(null);

  const updateFilter = (section, key, value) => {
    setFilterState((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [key]: value,
      },
    }));
  };

  const { data: categories } = useQuery({
    queryKey: ["category_search"],
    queryFn: () => fetchCategories(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data: volume } = useQuery({
    queryKey: ["volume_search"],
    queryFn: () => fetchVolumes(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data: author } = useQuery({
    queryKey: ["author_search"],
    queryFn: () => getAuthor(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data: subCategories } = useQuery({
    queryKey: ["subCategories_search", filterState.mainFilter.Category],
    queryFn: () =>
      getSubCategoriesByCategoryId(filterState.mainFilter.Category),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { data, mutate, isLoading } = useMutation(getAllFilteredArticles, {
    onSuccess: (data) => {},
    onError: (error) => {},
  });

  // Tanlangan tartibni yangilash
  const handleSortChange = (value) => {
    setFilterState((prevState) => {
      // Faqat bir xil tanlov qilish va boshqa tartiblash mezonini tozalash
      const newSort = {
        createdAt: value === "createdAt" ? "DESC" : null,
        viewsCount: value === "viewsCount" ? "DESC" : null,
      };
      return {
        ...prevState,
        sort: newSort,
      };
    });
    mutate({ data: filterState, page: 1, limit: 40 }); // Yangi tartib mezonini yuborish
  };

  const handleSearch = () => {
    mutate({ data: filterState, page: 1, limit: 40 });
  };

  useEffect(() => {
    mutate({ data: filterState, page: 1, limit: 40 });
  }, [filterState.sort.viewsCount, filterState.sort.createdAt]);

  const items = [
    {
      key: "1",
      label: "Maqola sarlavhasi bo'yicha",
      children: (
        <Input
          variant="outlined"
          size="middle"
          value={filterState.mainFilter.title}
          onChange={(e) => updateFilter("mainFilter", "title", e.target.value)}
          placeholder="Maqola sarlavhasini kiriting"
        />
      ),
    },
    {
      key: "2",
      label: "Nashrlar bo'yicha",
      children: (
        <Select
          allowClear={true}
          mode="multiple"
          variant="outlined"
          className="w-full"
          size="middle"
          value={filterState.mainFilter.volume}
          onChange={(values) => updateFilter("mainFilter", "volume", values)}
          options={volumeToValueAndLabel(volume, "id", "title")}
          placeholder="Nashrni tanlang!"
        />
      ),
    },
    {
      key: "3",
      label: "Yo'nalishlar bo'yicha",
      children: (
        <Select
          allowClear={true}
          mode="multiple"
          variant="outlined"
          className="w-full"
          size="middle"
          value={filterState.mainFilter.Category}
          onChange={(values) => updateFilter("mainFilter", "Category", values)}
          options={volumeToValueAndLabel(categories, "id", "name")}
          placeholder="Yo'nalishni tanlang!"
        />
      ),
    },
    {
      key: "4",
      label: "Yo'nalish sohalari bo'yicha",
      children: (
        <Select
          allowClear={true}
          mode="multiple"
          variant="outlined"
          className="w-full"
          size="middle"
          value={filterState.mainFilter.subCategory}
          onChange={(values) =>
            updateFilter("mainFilter", "subCategory", values)
          }
          options={volumeToValueAndLabel(subCategories, "id", "name")}
          placeholder="Yo'nalish sohasini tanlang!"
        />
      ),
    },
    {
      key: "5",
      label: "Mualliflar bo'yicha",
      children: (
        <Select
          allowClear={true}
          mode="multiple"
          variant="outlined"
          className="w-full"
          size="middle"
          value={filterState.mainFilter.author}
          onChange={(values) => updateFilter("mainFilter", "author", values)}
          filterOption={(input, option) =>
            option?.label.toLowerCase().includes(input.toLowerCase())
          }
          options={volumeToValueAndLabel(author, "id", "full_name")}
          placeholder="Mualliflar tanlang!"
        />
      ),
    },
  ];

  const items2 = [
    {
      key: "5",
      label: "Vaqt oralig'i bo'yicha",
      children: (
        <div className="w-full flex justify-center items-center gap-2">
          <DatePicker
            value={
              filterState.dateFilter.start
                ? dayjs(filterState.dateFilter.start)
                : null
            }
            onChange={(e) =>
              updateFilter("dateFilter", "start", dayjs(e).format("YYYY-MM-DD"))
            }
            rootClassName=""
            format={{
              format: "YYYY-MM-DD",
              type: "mask",
            }}
            placeholder="dan"
          />
          <DatePicker
            placeholder="gacha"
            value={
              filterState.dateFilter.end
                ? dayjs(filterState.dateFilter.end)
                : null
            }
            rootClassName=""
            onChange={(e) =>
              updateFilter("dateFilter", "end", dayjs(e).format("YYYY-MM-DD"))
            }
            format={{
              format: "YYYY-MM-DD",
              type: "mask",
            }}
          />
        </div>
      ),
    },
    {
      key: "5",
      label: "Qo'shimcha saralash",
      children: (
        <div>
          <Checkbox
            value={filterState.extraFilter.existDoi}
            ref={checkBoxDoi}
            onChange={(e) =>
              updateFilter("extraFilter", "existDoi", e.target.checked)
            }
          >
            DOI mavjudlari
          </Checkbox>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="w-full bg-white py-6">
        <Container className="main-font">
          <Breadcrumb
            items={[
              {
                onClick: () => navigate("/"),
                title: <HomeOutlined />,
                className: "cursor-pointer",
              },
              {
                title: (
                  <div className="flex items-center gap-1.5">
                    <BsSearch className="text-[12px]" />
                    <span
                      className="font-semibold
                  "
                    >
                      Qidiruv sahifasi
                    </span>
                  </div>
                ),
              },
            ]}
          />
        </Container>
      </div>
      <Container className="user-select-none">
        <div className="flex items-start max-md:flex-col justify-between gap-3">
          <div className="w-1/4 pt-10 sticky max-md:static top-0 max-md:w-full max-lg:w-1/2">
            <div className="flex items-center mb-5 justify-between">
              <h2 className="font-semibold text-lg">Saralash</h2>
              <Button
                onClick={() => {
                  setFilterState(initialState);
                  mutate({ data: initialState, page: 1, limit: 40 });
                }}
                size="small"
                type="default"
                className="bg-transparent border-0 shadow-none"
                icon={<BiTrash />}
              >
                Tozalash
              </Button>
            </div>
            <Collapse
              expandIcon={({ isActive }) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "transform 0.3s",
                    transform: !isActive ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                >
                  {isActive ? <MinusOutlined /> : <PlusOutlined />}
                </div>
              )}
              className="text-black font-semibold -tracking-tighter bg-white"
              accordion
              size="middle"
              defaultActiveKey={"1"}
              items={items}
            />
            <span className="w-full h-1 border-dashed border-1 bg-white block my-2"></span>
            <Collapse
              defaultActiveKey={"5"}
              expandIcon={({ isActive }) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "transform 0.4s",
                    transform: !isActive ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                >
                  {isActive ? <MinusOutlined /> : <PlusOutlined />}
                </div>
              )}
              className="text-black font-semibold -tracking-tighter bg-white"
              accordion
              size="middle"
              items={items2}
            />
            <Button
              onClick={handleSearch}
              type="primary"
              size="large"
              className="bg-sky-500 w-full mt-3"
            >
              Izlash
            </Button>
          </div>
          <div className="w-[72%] max-md:w-full">
            <div className="flex pt-10 justify-between max-md:static sticky-top bg-gray-100 z-10 w-full">
              <div className="flex flex-col">
                <span>
                  Topilgan maqolalar soni: <strong>{data?.filterItems}</strong>{" "}
                  ta
                </span>
              </div>
              <div className="flex items-center gap-3">
                {/* Ant Design Select componenti */}
                <Select
                  defaultValue="createdAt"
                  onChange={handleSortChange}
                  className="w-40"
                  placeholder="Saralash"
                >
                  <Select.Option value="createdAt">Sana</Select.Option>
                  <Select.Option value="viewsCount">
                    Ko'rishlar soni
                  </Select.Option>
                </Select>
              </div>
            </div>
            <div className="mt-[18px] px-3">
              <Row gutter={[16, 16]}>
                {isLoading
                  ? Array.from({
                      length: data?.filterItems ? data?.filterItems : 10,
                    }).map((_, index) => (
                      <Col key={index} xl={12}>
                        <div className="flex items-start gap-3 w-full">
                          {/* Rasm skeleti */}
                          <Skeleton.Image
                            style={{ width: 176, height: 144 }}
                            className="h-36 w-44"
                          />
                          <div>
                            {/* Matn skeleti */}
                            <Skeleton paragraph={{ rows: 1 }} active />
                            {/* Kiritish maydoni skeleti */}
                            <div className="pt-3 w-full">
                              <Skeleton.Input
                                style={{ width: "100%" }}
                                active
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))
                  : data?.data &&
                    data.data.map((item) => (
                      <Col key={item.id} xl={12}>
                        <Link to={`/articles/${item.slug}`} className={``}>
                          <div className="small-article__row">
                            <div className="small-article__img max-sm:max-w-max max-sm:">
                              {item.image ? (
                                <img
                                  src={`${process.env.REACT_APP_API_URL2}${item.image.file_path}`}
                                  className="h-full"
                                  alt="Maqola rasmi"
                                  loading="lazy"
                                />
                              ) : (
                                <img
                                  src="https://picsum.photos/50/50"
                                  loading="lazy"
                                  alt="Maqola rasmi"
                                />
                              )}
                            </div>

                            <div className="small-article__about">
                              <div
                                className={`small-article__type max-sm:flex-col-reverse max-sm:items-start`}
                              >
                                <span className="small-article__time">
                                  <i className="ri-calendar-2-line"></i>
                                  {moment(item.createdAt)
                                    .utc()
                                    .format("YYYY-MM-DD")}
                                </span>
                                <span className="small-article__category max-sm:m-0 max-sm:mb-1.5 max-sm:py-0.5">
                                  {item.category.name}
                                </span>
                              </div>
                              <h2 className="small-article__title">
                                {item.title}
                              </h2>
                              <div className="flex items-center justify-between">
                                <span className="small-article__author">
                                  {item.author.full_name}
                                </span>
                                <div className="flex items-center gap-1">
                                  <HiEye className="text-gray-400 text-sm" />
                                  <p className="text-gray-400 text-sm">
                                    {item.viewsCount}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    ))}
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SearchArticle;
