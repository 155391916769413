import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import Categories from "../../components/categories";
import Footer from "../../components/footer";
import TgAnimation from "../../components/telegram";
import "./publications.css";
import moment from "moment";
import "moment/locale/uz-latn";
import { Helmet } from "react-helmet";
import NavigateToback from "../../components/button/NavigateToback";

export const fetchVolumes = async () => {
  const options = {
    url: `${process.env.REACT_APP_API_URL2}/volume`,
    method: "GET",
  };

  const response = await axios.request(options);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error("Error fetching volumes");
  }
};

const Publications = () => {
  const { data: volumes = [] } = useQuery("volumes", fetchVolumes);

  const handleDownload = (event, path) => {
    event.preventDefault();
    event.stopPropagation();
    if (path) {
      window.open(`${process.env.REACT_APP_API_URL2}${path}`, "_blank");
    }
  };

  return (
    <>
      <Helmet>
        <title>Nashrlar</title>
        <meta name="publications" content="e-journal Nashrlar" />
        <meta
          name="keywords"
          content="e-journal, ilmiy nashrlar, ilmiy maqolalar, elektron jurnal, tadqiqot maqolalari, ilmiy tadqiqotlar, ilmiy jurnal, ilmiy nashrlar yuklab olish, akademik nashrlar, ilmiy maqola yozish, ilmiy maqolalarni yuklab olish, nordik e-journal, ilmiy jurnallar arxivi, ilmiy maqola nashri, tadqiqot natijalari, elektron nashrlar, ilmiy maqola to'plamlari"
        />
      </Helmet>
      <Container>
        <NavigateToback />
      </Container>
      <main className="publications-main">
        <>
          <section className="publications">
            <Container>
              <Row>
                <Col lg={8}>
                  <h2 className="publications-title max-sm:mb-3 max-sm:ml-0 title">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                        fill="#0196E3"
                      />
                    </svg>
                    Nashrlar
                  </h2>
                  {volumes.length > 0 &&
                    volumes?.map((item) => (
                      <Link
                        to={`/volume/${item.id}`}
                        rel="noreferrer"
                        className="publications-wrapper"
                        key={item.id}
                      >
                        <div className="publications-card">
                          <div className=" max-sm:w-full">
                            <img
                              src={`${process.env.REACT_APP_API_URL2}${item.image.file_path}`}
                              alt="Nashrning Rasmi"
                              width={540 + "px"}
                              height={410 + "px"}
                            />
                          </div>
                          <div className="publications-card__about">
                            <h3 className="publications-card__title title">
                              {item.title}
                            </h3>
                            <p className="publications-card__subtitle">
                              Nashr sanasi
                            </p>
                            <div className="puclications-card__category">
                              <span className="publications-card__time">
                                <i
                                  className="ri-calendar-2-line"
                                  style={{ marginRight: "10px" }}
                                ></i>
                                {moment(item.createdAt)
                                  .utc()
                                  .format("MMMM d, yyyy")}
                              </span>
                            </div>
                            <Button
                              style={{ marginTop: "10px" }}
                              onClick={(e) =>
                                handleDownload(e, item.source.file_path)
                              }
                            >
                              Yuklab olish
                            </Button>
                          </div>
                        </div>
                      </Link>
                    ))}
                </Col>
                <Col
                  className="publications-category__wrapper h-8 pt-3 sticky-top"
                  lg={4}
                >
                  <h2 className="publications-category__title title pl-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                        fill="#0196E3"
                      />
                    </svg>
                    Asosiy yo'nalishlar
                  </h2>
                  <Categories />
                </Col>
              </Row>
            </Container>
          </section>
        </>
        <footer className="publications-footer">
          <Footer />
        </footer>
        <TgAnimation />
      </main>
    </>
  );
};

export default React.memo(Publications);
