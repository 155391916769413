import Lottie from "lottie-react";
import telegramIcon from "../../assets/Animation/telegram-animation-2.json";

import "./telegram.css";
import { Link } from "react-router-dom";
import React from "react";

const TgAnimation = () => {
  return (
    <>
      <Link
        target="_blank"
        to={"https://t.me/journal_NIU"}
        className={"animation-telegram__icon"}
      >
        <Lottie animationData={telegramIcon}></Lottie>
      </Link>
    </>
  );
};

export default React.memo(TgAnimation);
