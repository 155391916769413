import React, { useEffect } from "react";
import { useInfiniteQuery } from "react-query";
import { Col, Container, Row } from "react-bootstrap";
import { Waypoint } from "react-waypoint";

// Components
import Footer from "../../components/footer";
import Cards from "../../components/cards";
// CSS
import "./archive.css";
import { fetchArticles } from "../../components/home-article/homeArticle.query";

const Archive = () => {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery("articlesDesktop", fetchArticles, {
    keepPreviousData: true,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      } else {
        return undefined;
      }
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <section className="archive-section">
        <Container>
          <h2 className="archive-title title">Maqolalar</h2>
          <Row className="feature-wrapper">
            {status === "loading" ? (
              <p>Loading...</p>
            ) : status === "error" ? (
              <p>Error: {error.message}</p>
            ) : (
              data?.pages?.map((group, i) => (
                <React.Fragment key={i}>
                  {group.data.map((item) => (
                    <Col
                      className="archive-card"
                      xs={11}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={3}
                      key={item.id}
                    >
                      <Cards
                        type="article-big"
                        views={item.viewsCount}
                        title={item.title}
                        keywords={item.abstract}
                        category={item.category.name}
                        image={`${process.env.REACT_APP_API_URL2}${item.image.file_path}`}
                        width="306px"
                        height="350px"
                        id={item.slug}
                        time={item.createdAt}
                        author={item.author.full_name}
                      />
                    </Col>
                  ))}
                  {hasNextPage && i === data.pages.length - 1 && (
                    <Waypoint onEnter={fetchNextPage} />
                  )}
                </React.Fragment>
              ))
            )}
            {isFetchingNextPage && <p>Loading more...</p>}
          </Row>
        </Container>
        <Footer />
      </section>
    </main>
  );
};

export default React.memo(Archive);
