import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import React, { useState, useEffect, useCallback, useContext } from "react";
import logoColorful from "../../assets/img/logo-colorful.png";
import notFound from "../../assets/img/not-found.png";
import axios from "axios";
import "./navbar.css";
import Login from "../login";
import Register from "../register";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import i18n from "../../i18next";
import { Button } from "antd";

export const Navbar = ({ home }) => {
  const [searchToggle, setSearchToggle] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [toggleNav, setToggleNav] = useState(false);
  // Bootstrap Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const url = window.location.href;
  const location = useLocation();
  const navigate = useNavigate();
  const { lang, setLang } = useContext(Context);

  const changeLanguageFunc = useCallback(
    async (lng) => {
      await i18n.changeLanguage(lng);
      setLang(lng);
      localStorage.setItem("lang", lng);
      const pathParts = location.pathname.split("/").filter(Boolean);
      if (pathParts.length > 0 && ["uz", "ru", "en"].includes(pathParts[0])) {
        pathParts[0] = lng;
        navigate("/" + pathParts.join("/"));
      }
    },
    [lang],
  );

  /** TAB */
  const [activeTab, setActiveTab] = useState("Register");
  const { t } = useTranslation();
  const [tabActive, tabSetActive] = useState(true);
  const handleTabChange = useCallback(
    (tab) => {
      setActiveTab(tab);

      if (tab === "Register") {
        tabSetActive(true);
      } else if (tab === "Login") {
        tabSetActive(false);
      }
    },
    [tabActive],
  );

  const handleInputChange = ({ target: { value } }) => setInputValue(value);
  const handleToggleNav = () => setToggleNav(!toggleNav);
  const onSubmit = useCallback(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL2}/article?limit=10&page=1`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({
        title: inputValue,
      }),
    }).then((response) => {
      setData(response.data.data);
    });
  }, [inputValue]);

  // Hamburger Toggle
  const toggleHamburger = () => setToggle(!toggle);
  // Toggle Search
  const toggleSearch = () => {
    navigate("/search-article");
  };
  // Register Modal
  const registerModal = () => {
    setShow(true);
  };
  // API
  useEffect(() => {
    if (searchToggle || toggle) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [searchToggle, toggle]);

  // Input submit time
  useEffect(() => {
    let timeout = null;
    clearTimeout(timeout);

    if (inputValue) {
      timeout = setTimeout(onSubmit, 200);
    } else {
      setData(null);
    }

    return () => clearTimeout(timeout);
  }, [inputValue, onSubmit]);

  const profileLink = () => {
    if (location.pathname.startsWith("/profile")) {
      localStorage.removeItem("accessToken");
      navigate("/");
    } else {
      navigate("/profile");
    }
  };

  return (
    <>
      {/* Desktop Container */}
      <div className="navbar-bg">
        <div className="flex items-center justify-center">
          <nav
            className={
              home
                ? "navbar navbar_home d-flex justify-content-between"
                : "navbar d-flex justify-content-between"
            }
          >
            <div className="wrapper">
              {/* Logo */}
              <Link onClick={handleToggleNav} className="nav-logo" to={"/"}>
                {/*Nordik*/}
                <img src={logoColorful} alt="Rasm" width={"200px"} />
              </Link>
              {/* Links */}
              <ul className="link-list__first">
                <li
                  onClick={handleToggleNav}
                  className={`link-item link-item__left ${
                    url ===
                    `${process.env.REACT_APP_API_URL_LOCAL}/about-journal`
                      ? "link-item__active"
                      : ""
                  }`}
                >
                  <Link to="/about-journal">{t("jurnal_haqida")}</Link>
                </li>
                <li
                  onClick={handleToggleNav}
                  className={`link-item link-item__left ${
                    url === `${process.env.REACT_APP_API_URL_LOCAL}/instruction`
                      ? "link-item__active"
                      : ""
                  }`}
                >
                  <Link to="/instruction">{t("yo_riqnoma")}</Link>
                </li>
                <li
                  onClick={handleToggleNav}
                  className={`link-item link-item__left ${
                    url ===
                    `${process.env.REACT_APP_API_URL_LOCAL}/publications`
                      ? "link-item__active"
                      : ""
                  }`}
                >
                  <Link to="/publications">{t("nashrlar")}</Link>
                </li>
                <li
                  onClick={handleToggleNav}
                  className={`link-item link-item__left ${
                    url === `${process.env.REACT_APP_API_URL_LOCAL}/category`
                      ? "link-item__active"
                      : ""
                  }`}
                >
                  <Link to="/category">{t("asosiy_yo_nalishlar")}</Link>
                </li>
                <li
                  onClick={handleToggleNav}
                  className={`link-item link-item__left ${
                    url === `${process.env.REACT_APP_API_URL_LOCAL}/connection`
                      ? "link-item__active"
                      : ""
                  }`}
                >
                  <Link to="/connection">{t("bog_lanish")}</Link>
                </li>
              </ul>

              <button
                type="button"
                onClick={toggleSearch}
                className="nav-search pt-2 search-open link-item link-item__left link-item__left-icon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 101 101"
                  id="search"
                >
                  <path d="M63.3 59.9c3.8-4.6 6.2-10.5 6.2-17 0-14.6-11.9-26.5-26.5-26.5S16.5 28.3 16.5 42.9 28.4 69.4 43 69.4c6.4 0 12.4-2.3 17-6.2l20.6 20.6c.5.5 1.1.7 1.7.7.6 0 1.2-.2 1.7-.7.9-.9.9-2.5 0-3.4L63.3 59.9zm-20.4 4.7c-12 0-21.7-9.7-21.7-21.7s9.7-21.7 21.7-21.7 21.7 9.7 21.7 21.7-9.7 21.7-21.7 21.7z"></path>
                </svg>
              </button>

              {/* Profile */}
              <button
                className={`profile-btn mt-1.5 ${
                  localStorage.getItem("accessToken") &&
                  location.pathname.startsWith("/profile")
                    ? "block"
                    : "hidden"
                }`}
                onClick={profileLink}
              >
                {/* <Link> */}
                {location.pathname.startsWith("/profile") ? (
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5 7.5L10.5 10.75M13.5 7.5L10.5 4.5M13.5 7.5L4 7.5M8 13.5H1.5L1.5 1.5L8 1.5"
                      stroke="#000000"
                    />
                  </svg>
                ) : (
                  ""
                )}
                {/* </Link> */}
              </button>

              {/* Enter */}
              <button
                onClick={registerModal}
                className={`block max-[1200px]:hidden bg-blue-500 text-white py-1.5 rounded-md font-sans px-2 ${
                  localStorage.getItem("accessToken") ? "hidden" : "block"
                }`}
              >
                {t("maqola_yuborish")}
              </button>
              <button
                onClick={() => {
                  navigate("/profile");
                  setToggle(false);
                }}
                className={`block bg-blue-500 max-xl:hidden text-white py-1.5 rounded-md font-sans px-7 ${
                  localStorage.getItem("accessToken") &&
                  !location.pathname.startsWith("/profile")
                    ? "block"
                    : "hidden"
                }`}
              >
                {t("profile_button")}
              </button>
              {/* Hamburger Toggle */}
              <button className="nav-menu" onClick={toggleHamburger}>
                <i className="ri-menu-line"></i>
              </button>
            </div>
          </nav>
        </div>
      </div>

      {/* Hamburger */}
      <div>
        <Container
          className={`mobile-hamburger ${
            toggle ? "hamburger-active" : "hamburger-anactive"
          }`}
        >
          <div>
            <nav className="mobile-nav">
              <button onClick={toggleHamburger} className="mobile-close__icon">
                <i className="ri-close-line"></i>
              </button>
              <ul className="mobile-wrapper">
                <li
                  onClick={toggleHamburger}
                  className={`mobile-item ${
                    url ===
                    `${process.env.REACT_APP_API_URL_LOCAL}/about-journal`
                      ? "mobile-item__active"
                      : ""
                  }`}
                >
                  <i className="ri-home-5-line"></i>
                  <Link to={"/about-journal"}>{t("jurnal_haqida")}</Link>
                </li>
                <li
                  onClick={toggleHamburger}
                  className={`mobile-item ${
                    url ===
                    `${process.env.REACT_APP_API_URL_LOCAL}/publications`
                      ? "mobile-item__active"
                      : ""
                  }`}
                >
                  <i className="ri-article-line"></i>
                  <Link to={"/publications"}>{t("nashrlar")}</Link>
                </li>
                <li
                  onClick={toggleHamburger}
                  className={`mobile-item ${
                    url === `${process.env.REACT_APP_API_URL_LOCAL}/category`
                      ? "mobile-item__active"
                      : ""
                  }`}
                >
                  <i className="ri-menu-search-line"></i>
                  <Link to={"/category"}>{t("asosiy_yo_nalishlar")}</Link>
                </li>
                <li
                  onClick={toggleHamburger}
                  className={`mobile-item ${
                    url === `${process.env.REACT_APP_API_URL_LOCAL}/connection`
                      ? "mobile-item__active"
                      : ""
                  }`}
                >
                  <i className="ri-links-line"></i>
                  <Link to={"/connection"}>{t("bog_lanish")}</Link>
                </li>
                <li
                  onClick={toggleHamburger}
                  className={`mobile-item ${
                    url === `${process.env.REACT_APP_API_URL_LOCAL}/instruction`
                      ? "mobile-item__active"
                      : ""
                  }`}
                >
                  <i className="ri-file-info-line"></i>
                  <Link to={"/instruction"}>{t("yo_riqnoma")}</Link>
                </li>
                <li>
                  <Button
                    onClick={() => {
                      navigate("/profile");
                      setToggle(false);
                    }}
                    type="primary"
                    className={`block mt-2 w-full  text-white  rounded-md font-sans px-2 ${
                      localStorage.getItem("accessToken") ? "block" : "hidden"
                    }`}
                  >
                    {t("profile_button")}
                  </Button>
                  <Button
                    onClick={() => {
                      registerModal();
                      setToggle(false);
                    }}
                    type="primary"
                    className={`block w-full bg-blue-500 text-white rounded-md font-sans px-2 ${
                      localStorage.getItem("accessToken") ? "hidden" : "block"
                    }`}
                  >
                    {t("maqola_yuborish")}
                  </Button>
                </li>
              </ul>
            </nav>
            <div className="fixed-bottom bottom-2 px-2">
              {/*<ul className="flex justify-between w-full mt-2 items-center">*/}
              {/*  <li className="link-item link-item__right">*/}
              {/*    <Link to="" onClick={() => changeLanguageFunc("uz")}>*/}
              {/*      <svg*/}
              {/*        xmlns="http://www.w3.org/2000/svg"*/}
              {/*        aria-hidden="true"*/}
              {/*        className="iconify iconify--twemoji"*/}
              {/*        viewBox="0 0 36 36"*/}
              {/*      >*/}
              {/*        <path*/}
              {/*          fill="#0099B5"*/}
              {/*          d="M0 9v4h36V9a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4z"*/}
              {/*        />*/}
              {/*        <path*/}
              {/*          fill="#1EB53A"*/}
              {/*          d="M36 27v-4H0v4a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4z"*/}
              {/*        />*/}
              {/*        <path fill="#CE1126" d="M0 13h36v1.5H0zm0 8.5h36V23H0z" />*/}
              {/*        <path fill="#EEE" d="M0 18v3.5h36v-7H0z" />*/}
              {/*        <path*/}
              {/*          fill="#FFF"*/}
              {/*          d="M4.2 9.16a3.12 3.12 0 0 1 2.6-3.08 3.12 3.12 0 1 0 0 6.16 3.12 3.12 0 0 1-2.6-3.08zm4.68 2.3-.14-.43-.14.43h-.45l.37.27-.14.43.36-.27.37.27-.14-.43.37-.27zm2.7 0-.14-.43-.14.43h-.45l.37.27-.14.43.36-.27.37.27-.14-.43.37-.27zm-.14-2.92-.14.43h-.45l.37.26-.14.43.36-.26.37.26-.14-.43.37-.26H12zm2.85 2.92-.14-.43-.14.43h-.46l.37.27-.14.43.37-.27.36.27-.14-.43.37-.27zm-.14-2.92-.14.43h-.46l.37.26-.14.43.37-.26.36.26-.14-.43.37-.26h-.45zm0-2.5-.14.43h-.46l.37.27-.14.43.37-.27.36.27-.14-.43.37-.27h-.45zm2.84 5.42-.14-.43-.14.43h-.45l.36.27-.14.43.37-.27.37.27-.14-.43.36-.27zm-.14-2.92-.14.43h-.45l.36.26-.14.43.37-.26.37.26-.14-.43.36-.26H17zm0-2.5-.14.43h-.45l.36.27-.14.43.37-.27.37.27-.14-.43.36-.27H17zm2.84 5.42-.14-.43-.14.43h-.45l.37.27-.14.43.36-.27.37.27-.14-.43.36-.27zm-.14-2.92-.14.43h-.45l.37.26-.14.43.36-.26.37.26-.14-.43.36-.26h-.46zm0-2.5-.14.43h-.45l.37.27-.14.43.36-.27.37.27-.14-.43.36-.27h-.46z"*/}
              {/*        />*/}
              {/*      </svg>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*  <li className="link-item link-item__right">*/}
              {/*    <Link to="" onClick={() => changeLanguageFunc("en")}>*/}
              {/*      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 30">*/}
              {/*        <clipPath id="t">*/}
              {/*          <path d="M25,15h25v15zv15h-25zh-25v-15zv-15h25z" />*/}
              {/*        </clipPath>*/}
              {/*        <path d="M0,0v30h50v-30z" fill="#012169" />*/}
              {/*        <path*/}
              {/*          d="M0,0 50,30M50,0 0,30"*/}
              {/*          stroke="#fff"*/}
              {/*          strokeWidth="6"*/}
              {/*        />*/}
              {/*        <path*/}
              {/*          d="M0,0 50,30M50,0 0,30"*/}
              {/*          clipPath="url(#t)"*/}
              {/*          stroke="#C8102E"*/}
              {/*          strokeWidth="4"*/}
              {/*        />*/}
              {/*        <path*/}
              {/*          d="M-1 11h22v-12h8v12h22v8h-22v12h-8v-12h-22z"*/}
              {/*          fill="#C8102E"*/}
              {/*          stroke="#FFF"*/}
              {/*          strokeWidth="2"*/}
              {/*        />*/}
              {/*      </svg>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*  <li className="link-item link-item__right">*/}
              {/*    <Link to="" onClick={() => changeLanguageFunc("ru")}>*/}
              {/*      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 24">*/}
              {/*        <path fill="#fff" d="M0 0h32v24H0z" />*/}
              {/*        <path fill="#d52b1e" d="M0 8h32v8H0z" />*/}
              {/*        <path fill="#0039a6" d="M0 16h32v8H0z" />*/}
              {/*      </svg>*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
          </div>
        </Container>
        <div
          onClick={() => setToggle(false)}
          className={`${
            toggle ? "block" : "hidden"
          } bg-[rgba(256,256,256,0.4)] backup backdrop-blur-sm fixed w-full top-0 h-full z-[990]`}
        ></div>
      </div>
      {/* Register Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="register-modal__wrapper">
            <div className={"register-modal__top"}>
              <div
                className={`modal-tab__bg ${
                  tabActive ? `translate-x-0` : `translate-x-100`
                }`}
              ></div>

              <button
                onClick={() => handleTabChange("Register")}
                className={`register ${
                  tabActive === true
                    ? "modal-title-active"
                    : "modal-title-anactive"
                }`}
              >
                Ro‘yxatdan o‘tish
              </button>
              <button
                onClick={() => handleTabChange("Login")}
                className={`login ${
                  tabActive === false
                    ? "modal-title-active"
                    : "modal-title-anactive"
                }`}
              >
                Kirish
              </button>
            </div>

            {activeTab === "Register" && (
              <div>
                <Register close={handleClose} />
              </div>
            )}
            {activeTab === "Login" && (
              <div>
                <Login close={handleClose} />
              </div>
            )}
          </div>
        </Modal.Body>
        <div className="footer-modal"></div>
      </Modal>
    </>
  );
};

export default React.memo(Navbar);
