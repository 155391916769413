/** @format */

import React, { useMemo, useCallback } from "react";
import { useParams } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { fetchCategoryArticles, getCategoryInfo } from "./query.article";
import Footer from "../../components/footer";
import Cards from "../../components/cards";
import "./archive.css";

const CategoryArticles = () => {
  const { categoryID } = useParams();

  const fetchCategoryArticlesCallback = useCallback(
    () => fetchCategoryArticles(categoryID),
    [categoryID],
  );
  const getCategoryInfoCallback = useCallback(
    () => getCategoryInfo(categoryID),
    [categoryID],
  );

  const {
    data: articlesData,
    error: articlesError,
    isLoading: articlesLoading,
  } = useQuery(["categoryArticles", categoryID], fetchCategoryArticlesCallback);

  const {
    data: categoryData,
    error: categoryError,
    isLoading: categoryLoading,
  } = useQuery(["categoryInfo", categoryID], getCategoryInfoCallback);

  const isLoading = useMemo(
    () => articlesLoading || categoryLoading,
    [articlesLoading, categoryLoading],
  );

  return (
    <>
      <main style={{ minHeight: "calc(100vh - 102px)" }}>
        <section className="archive-section">
          {isLoading && <div>Loading...</div>}
          {articlesError && <div>Error loading articles</div>}
          {categoryError && <div>Error loading category info</div>}
          {!isLoading && articlesData && categoryData && (
            <Container>
              <h2 className="archive-title title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                    fill="#0196E3"
                  />
                </svg>
                {categoryData.name}
              </h2>
              <Row className="feature-wrapper">
                {articlesData.map((item) => (
                  <Col
                    className="archive-card"
                    xs={11}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={3}
                    key={item.id}
                  >
                    <Cards
                      type="article-big"
                      title={item.title}
                      views={item.viewsCount}
                      keywords={item.abstract}
                      category={item.category.name}
                      image={`${process.env.REACT_APP_API_URL2}${item.image.file_path}`}
                      width="306px"
                      height="350px"
                      id={item.slug}
                      time={item.createdAt}
                      author={item.author.full_name}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
};

export default React.memo(CategoryArticles);
