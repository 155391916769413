import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import "./category.css";
import TgAnimation from "../../components/telegram";
import { useQuery } from "react-query";
import { fetchCategories } from "./query.category";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import NavigateToback from "../../components/button/NavigateToback";

const Category = () => {
  const {
    data: categories = [],
    isLoading,
    isError,
  } = useQuery("categories", fetchCategories);

  return (
    <>
      <Helmet>
        <title>Asosiy yo'nalishlar</title>
        <meta
          name="category"
          content="Ilmiy maqolalar, nashrlar va asosiy yo'nalishlar bo'yicha to'liq ma'lumotlar."
        />
        <meta
          name="keywords"
          content="ilmiy nashrlar, pedagogika fanlari, psixologiya fanlari, iqtisodiyot sohasi, ijtimoiy fanlar, filologiya fanlari, e-journal, elektron jurnal, ilmiy tadqiqotlar, akademik nashrlar, tadqiqot maqolalari, ilmiy jurnal, ilmiy maqola yozish, ilmiy maqolalarni yuklab olish, ilmiy jurnallar arxivi, ilmiy maqola nashri, tadqiqot natijalari, elektron nashrlar, ilmiy maqola to'plamlari"
        />
      </Helmet>

      <main className="category-main">
        <Container>
          <NavigateToback />
        </Container>
        <>
          <section className="category-section">
            <Container>
              <h2 className="category-page__title title">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                    fill="#0196E3"
                  />
                </svg>
                Asosiy yo'nalishlar
              </h2>
              <div className="grid grid-cols-2 max-xl:grid-cols-1 gap-3">
                {isLoading ? (
                  <Skeleton count={5} height={300} />
                ) : isError ? (
                  <div>Error fetching categories</div>
                ) : (
                  categories.length > 0 &&
                  categories.map((item) => (
                    <div key={item.id}>
                      <Link to={`/category/${item.id}`}>
                        <div className="category-card__wrapper h-full max-xl:h-full">
                          <div className="category-card__img">
                            <img
                              src={`${process.env.REACT_APP_API_URL2}${item.file?.file_path}`}
                              className="h-full"
                              alt="Categoriya rasmi"
                            />
                          </div>
                          <div className="category-card__text">
                            <h3 className="category-card__title title">
                              🗂 {item.name}
                            </h3>
                            <div>
                              <table>
                                <tbody>
                                  {item?.subCategories?.map((e, index) => (
                                    <tr
                                      key={index}
                                      className="border-t-2 border-gray-200"
                                    >
                                      <td className="py-2 px-4">{e?.name}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                )}
              </div>
            </Container>
          </section>
        </>
        <TgAnimation />
      </main>
      <footer className="category-page__footer">
        <Footer />
      </footer>
    </>
  );
};

export default React.memo(Category);
