/** @format */

import axios from 'axios';

export const fetchCategoryArticles = async (categoryID) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_URL2}/article/user/category/${categoryID}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
};

export const getCategoryInfo = async (categoryID) => {
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL2}/category/${categoryID}`
    );

    if (response.status === 200) {
        return response.data;
    } else {
        throw new Error('Failed to fetch category info');
    }
};
