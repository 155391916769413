/** @format */

import axios from "axios";
import React, { createContext, useReducer, useEffect, useState } from "react";

export const Context = createContext();

const initialState = {
  article: [],
  bestArticle: [],
  randomArticle: [],
  category: [],
  volume: [],
  articlePage: 0,
  limit: 8,
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_ARTICLE":
      return {
        ...state,
        article: [...state.article, ...payload],
      };
    case "SET_CATEGORY":
      return {
        ...state,
        category: payload,
      };
    case "SET_VOLUME":
      return {
        ...state,
        volume: payload,
      };
    case "INCREMENT_PAGE":
      return {
        ...state,
        articlePage: state.articlePage + 1,
      };
    default:
      return state;
  }
};

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [lang, setLang] = useState(
    () =>
      localStorage.getItem("lang") || localStorage.getItem("defLang") || "uz",
  );

  const fetchData = async (url, type, data = {}) => {
    try {
      const response = await axios.post(url, data, {
        headers: { "Content-Type": "application/json" },
      });
      dispatch({ type: type, payload: response.data.data });
    } catch (error) {}
  };

  const fetchDatas = async (url, type) => {
    try {
      const response = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });
      dispatch({ type: type, payload: response.data });
    } catch (error) {}
  };

  useEffect(() => {
    fetchData(
      `https://journal2.nordicun.uz/article?limit=10&page=${
        state.articlePage + 1
      }`,
      "SET_ARTICLE",
    );
  }, [state.articlePage]);

  useEffect(() => {
    fetchDatas(`https://journal2.nordicun.uz/category`, "SET_CATEGORY");
  }, []);

  const value = { ...state, dispatch, setLang, lang };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Provider;
