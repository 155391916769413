import React from "react";
import { useInfiniteQuery } from "react-query";
import { Col, Container, Row } from "react-bootstrap";
import { Waypoint } from "react-waypoint";
import Cards from "../cards";
import "./home-article.css";

const fetchArticles = async ({ pageParam = 1 }) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL2}/article?page=${pageParam}&limit=2`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return {
    data: data.data,
    nextPage: pageParam + 1,
    totalPages: data.totalPages,
  };
};

const HomeArticle = () => {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery("articlessss", fetchArticles, {
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage <= lastPage.totalPages) {
        return lastPage.nextPage;
      } else {
        return undefined;
      }
    },
  });

  return (
    <section className="media-articles">
      <Container>
        <Row className="feature-wrapper">
          {status === "loading" ? (
            <p>Loading...</p>
          ) : status === "error" ? (
            <p>Error: {error.message}</p>
          ) : (
            data?.pages?.map((group, i) => (
              <React.Fragment key={i}>
                {group?.data?.map((item) => (
                  <Col className="article-card" lg={6} key={item.id}>
                    <Cards
                      type="article-big"
                      views={item.viewsCount}
                      title={item.title}
                      keywords={item.abstract}
                      category={item.category.name}
                      image={`${process.env.REACT_APP_API_URL2}${item?.image?.file_path}`}
                      id={item.slug}
                      time={item.createdAt}
                      author={item.author.full_name}
                    />
                  </Col>
                ))}
                {hasNextPage && i === data.pages.length - 1 && (
                  <Waypoint onEnter={fetchNextPage} />
                )}
              </React.Fragment>
            ))
          )}
          {isFetchingNextPage && <p>Loading more...</p>}
        </Row>
      </Container>
    </section>
  );
};

export default React.memo(HomeArticle);
