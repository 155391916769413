import axios from "axios";

export const getArticleBySlug = async (slug) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL2}/article/user/slug/${slug}`,
    {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
      },
    },
  );
  return response.data;
};
