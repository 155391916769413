import axios from "axios";

export const fetchArticleById = async (id) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL2}/author/article_author_user`,
    {
      id: id,
    },
  );
  return response?.data;
};
