/** @format */

import axios from 'axios';

export const fetchVolumes = async () => {
    const options = {
        url: `${process.env.REACT_APP_API_URL2}/volume`,
        method: "GET",
    };

    const response = await axios.request(options);
    if (response.status === 200) {
        return response.data;
    } else {
        throw new Error('Error fetching volumes');
    }
};
