import React from 'react';
import errorPage from '../../assets/img/404.jfif';
import leyer1 from '../../assets/img/Ellipse.svg'
import ellipseRight  from '../../assets/img/ellipse3.svg'
import {useNavigate} from "react-router-dom";


const Page404 = () => {
    const navigate=useNavigate()
    return (
        <div id="page404 " className="relative overflow-hidden">
            <div className="top-6">
                <img src={leyer1} className="absolute -left-14 pt-3 max-sm:w-[140px] max-xl:w-[200px]"/>
                <img src={ellipseRight} className="absolute -right-0 -bottom-1 max-sm:w-[140px] max-xl:w-[200px]"/>
            </div>
            <div id="page404" className="flex-row  items-center  bg-white ">
                <div className="flex justify-center">
                    <img src={errorPage} alt="404" className=" w-[500px] h-[500px] mt-3"/>
                </div>
                <div className=" text-center z-10 absolute bottom-[10%] left-[50%] -translate-x-1/2 ">
                    <h1 className="font-bold text-blue-500 max-sm:hidden">Something went wrong</h1>
                    <h2 className="font-bold text-blue-500 max-sm:hidden">Sorry, we can't find the page you're looking for.</h2>
                    <button onClick={() => navigate('/')}
                            className="bg-blue-500 text-white py-2 px-2 rounded-lg mt-3 max-sm:mb-6 ">Back to Home
                    </button>
                </div>
                <div>

                </div>
            </div>

        </div>
    );
};

export default Page404;
