import React, { useState } from "react";

import "./accordion-item.css";

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion-item ${isOpen ? "open" : ""}`} onClick={toggleAccordion}>
      <div className="accordion-title text-cyan-500">
      {isOpen ? (<span>-</span>): (<span>+</span>)}
        {title}
      </div>
      <div className="accordion-content text-cyan-950" style={{ maxHeight: isOpen ? "500px" : "0" }}>
        {content}
      </div>
    </div>
  );
};

export default React.memo(AccordionItem);

