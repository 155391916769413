import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import "./categories.css";

const fetchCategories = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL2}/category`,
  );
  return response.status === 200 ? response.data : [];
};

const fetchArticlesCountByCategoryID = async (categoryID) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL2}/article/user/category/${categoryID}`,
  );
  return response.status === 200 ? response.data.length : 0;
};

const Categories = () => {
  const {
    data: categories = [],
    isLoading,
    error,
  } = useQuery("categories", fetchCategories);

  const categoriesWithArticles = useQuery(
    ["categoriesWithArticles", categories],
    async () => {
      return await Promise.all(
        categories.map(async (category) => {
          const articlesCount = await fetchArticlesCountByCategoryID(
            category.id,
          );
          return { ...category, articlesCount };
        }),
      );
    },
    { enabled: categories.length > 0 },
  );

  if (isLoading || categoriesWithArticles.isLoading) {
    return <div>Loading...</div>;
  }

  if (error || categoriesWithArticles.error) {
    return <div>Error loading categories</div>;
  }

  return (
    <div className="category-wrapper sticky-top">
      <div className="category-box">
        <div className="category-item">
          {categoriesWithArticles.data.length > 0 &&
            categoriesWithArticles.data.map((category) => (
              <Link
                to={`/category/${category.id}`}
                className="category-wrappers"
                key={category.id}
              >
                <div className="category-img">
                  <img
                    src={`${process.env.REACT_APP_API_URL2}${category.file?.file_path}`}
                    alt="Category"
                  />
                </div>
                <div className="category-about">
                  <div className="category-title">{category.name}</div>
                  <span className="category-count">
                    {category.articlesCount} ta maqola
                  </span>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Categories);
