import axios from "axios";

export const Necessary = async ({ articles, topArticles, lastArticles }) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL2}/article/necessary?articles=${articles}&topArticles=${topArticles}&lastArticles=${lastArticles}`,
  );
  return response.data;
};

export const getVolume = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL2}/volume`);
  return response.data;
};

export const getAllNews = async (lang) => {
  console.log(lang);
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL2}/news/list?page=1&limit=10&lang=${lang || "en"}`,
  );
  return response.data;
};
