/** @format */

import axios from "axios";

export const registerUser = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL2}/author/create`,
      {
        phone_number: `+${data.number}`,
        password: data.password,
        full_name: data.full_name,
        science_degree: data.science_degree,
        birthday: data.birthday,
        job: data.job,
        place_position: data.place_position,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error("Failed to register user");
    }
  } catch (error) {
    console.error("Register user error:", error);
    throw error;
  }
};

const getAuthors = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL2}/author/get`,
    {},
  );
};
