import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Autoplay, Navigation, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Cards from "../../components/cards";
import TgAnimation from "../../components/telegram";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { Necessary, getVolume, getAllNews } from "./query.home";
import "./home.css";
import "swiper/css";
import "swiper/css/navigation";
import image1 from "../../assets/img/SLIDER-1.webp";
import image2 from "../../assets/img/SLIDER-2.webp";
import image3 from "../../assets/img/SLIDER-3.webp";
import sherzod from "../../assets/img/mobile_image.webp";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import i18n from "../../i18next";
import SocialMediaIcons from "../../components/social-media-icons/SocialMediaIcons";
import { Context } from "../../context";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang, setLang } = useContext(Context);
  const changeLanguageFunc = useCallback(
    async (lng) => {
      await i18n.changeLanguage(lng);
      setLang(lng);
      localStorage.setItem("lang", lng);
      const pathParts = location.pathname.split("/").filter(Boolean);
      if (pathParts.length > 0 && ["uz", "ru", "en"].includes(pathParts[0])) {
        pathParts[0] = lng;
        navigate("/" + pathParts.join("/"));
      }
    },
    [lang],
  );

  const { data: allData = [] } = useQuery(
    "articles",
    () =>
      Necessary({
        lastArticles: 8,
        topArticles: 5,
        articles: 8,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  const { data: News } = useQuery({
    queryKey: ["news", lang],
    queryFn: () => getAllNews(lang),
  });

  const { data: volumes = [] } = useQuery("volumes", getVolume, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const articles = allData?.articles || [];
  const topArticles = allData?.topArticles || [];
  const lastArticles = allData?.lastArticles || [];

  const images = useMemo(() => {
    return [
      { src: image1, alt: "Image 1" },
      { src: image2, alt: "Image 2" },
      { src: image3, alt: "Image 3" },
      { src: sherzod, alt: "Image 4" },
    ];
  }, []);

  const memoizedSlides = useMemo(() => {
    return volumes.map((volume) => (
      <SwiperSlide key={volume.id}>
        <Link to={`/volume/${volume.id}`} rel="noreferrer">
          <img
            className="h-[500px] max-sm:h-[400px]"
            src={`${process.env.REACT_APP_API_URL2}${volume?.image?.file_path}`}
            alt={volume.title}
            loading="lazy"
          />
        </Link>
      </SwiperSlide>
    ));
  }, [volumes]);

  useEffect(() => {
    i18n.changeLanguage(lang).then();
  }, [lang]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Nordic journal</title>
        <meta name="description" content="e-journal asosiy sahifasi" />
        <meta name="keywords" content="e-journal, nordik e-journal, journal" />
        <meta property="og:title" content="Nordic journal" />
        <meta property="og:description" content="e-journal asosiy sahifasi" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={images[0].src || ""} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nordic journal" />
        <meta name="twitter:description" content="e-journal asosiy sahifasi" />
        <meta name="twitter:image" content={images[0].src || ""} />
      </Helmet>
      <header className={"home-header"}>
        {/*<ul className="link-list__second max-xl:hidden">*/}
        {/*  <li className="link-item link-item__right">*/}
        {/*    <Link to="" onClick={() => changeLanguageFunc("uz")}>*/}
        {/*      <svg*/}
        {/*        xmlns="http://www.w3.org/2000/svg"*/}
        {/*        aria-hidden="true"*/}
        {/*        className="iconify iconify--twemoji"*/}
        {/*        viewBox="0 0 36 36"*/}
        {/*      >*/}
        {/*        <path*/}
        {/*          fill="#0099B5"*/}
        {/*          d="M0 9v4h36V9a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4z"*/}
        {/*        />*/}
        {/*        <path*/}
        {/*          fill="#1EB53A"*/}
        {/*          d="M36 27v-4H0v4a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4z"*/}
        {/*        />*/}
        {/*        <path fill="#CE1126" d="M0 13h36v1.5H0zm0 8.5h36V23H0z" />*/}
        {/*        <path fill="#EEE" d="M0 18v3.5h36v-7H0z" />*/}
        {/*        <path*/}
        {/*          fill="#FFF"*/}
        {/*          d="M4.2 9.16a3.12 3.12 0 0 1 2.6-3.08 3.12 3.12 0 1 0 0 6.16 3.12 3.12 0 0 1-2.6-3.08zm4.68 2.3-.14-.43-.14.43h-.45l.37.27-.14.43.36-.27.37.27-.14-.43.37-.27zm2.7 0-.14-.43-.14.43h-.45l.37.27-.14.43.36-.27.37.27-.14-.43.37-.27zm-.14-2.92-.14.43h-.45l.37.26-.14.43.36-.26.37.26-.14-.43.37-.26H12zm2.85 2.92-.14-.43-.14.43h-.46l.37.27-.14.43.37-.27.36.27-.14-.43.37-.27zm-.14-2.92-.14.43h-.46l.37.26-.14.43.37-.26.36.26-.14-.43.37-.26h-.45zm0-2.5-.14.43h-.46l.37.27-.14.43.37-.27.36.27-.14-.43.37-.27h-.45zm2.84 5.42-.14-.43-.14.43h-.45l.36.27-.14.43.37-.27.37.27-.14-.43.36-.27zm-.14-2.92-.14.43h-.45l.36.26-.14.43.37-.26.37.26-.14-.43.36-.26H17zm0-2.5-.14.43h-.45l.36.27-.14.43.37-.27.37.27-.14-.43.36-.27H17zm2.84 5.42-.14-.43-.14.43h-.45l.37.27-.14.43.36-.27.37.27-.14-.43.36-.27zm-.14-2.92-.14.43h-.45l.37.26-.14.43.36-.26.37.26-.14-.43.36-.26h-.46zm0-2.5-.14.43h-.45l.37.27-.14.43.36-.27.37.27-.14-.43.36-.27h-.46z"*/}
        {/*        />*/}
        {/*      </svg>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*  <li className="link-item link-item__right">*/}
        {/*    <Link to="" onClick={() => changeLanguageFunc("en")}>*/}
        {/*      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 30">*/}
        {/*        <clipPath id="t">*/}
        {/*          <path d="M25,15h25v15zv15h-25zh-25v-15zv-15h25z" />*/}
        {/*        </clipPath>*/}
        {/*        <path d="M0,0v30h50v-30z" fill="#012169" />*/}
        {/*        <path d="M0,0 50,30M50,0 0,30" stroke="#fff" strokeWidth="6" />*/}
        {/*        <path*/}
        {/*          d="M0,0 50,30M50,0 0,30"*/}
        {/*          clipPath="url(#t)"*/}
        {/*          stroke="#C8102E"*/}
        {/*          strokeWidth="4"*/}
        {/*        />*/}
        {/*        <path*/}
        {/*          d="M-1 11h22v-12h8v12h22v8h-22v12h-8v-12h-22z"*/}
        {/*          fill="#C8102E"*/}
        {/*          stroke="#FFF"*/}
        {/*          strokeWidth="2"*/}
        {/*        />*/}
        {/*      </svg>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*  <li className="link-item link-item__right">*/}
        {/*    <Link to="" onClick={() => changeLanguageFunc("ru")}>*/}
        {/*      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 24">*/}
        {/*        <path fill="#fff" d="M0 0h32v24H0z" />*/}
        {/*        <path fill="#d52b1e" d="M0 8h32v8H0z" />*/}
        {/*        <path fill="#0039a6" d="M0 16h32v8H0z" />*/}
        {/*      </svg>*/}
        {/*    </Link>*/}
        {/*  </li>*/}
        {/*</ul>*/}
      </header>
      <>
        <div className="section-wrapper px-3">
          <img
            src={images[3].src}
            alt={images[3].alt}
            height={"auto"}
            width={"100%"}
            loading="lazy"
            className="rounded-lg hidden max-sm:block object-contain"
          />
          <section className="section-about block max-sm:hidden">
            <Swiper
              slidesPerGroup={1}
              spaceBetween={30}
              lazy={true}
              effect={"creative"}
              loop={true}
              autoplay={{
                reverseDirection: true,
                delay: 3500,
              }}
              speed={1500}
              modules={[EffectFade, Autoplay]}
              className="mySwiper pt-0 w-full m-0"
            >
              <SwiperSlide>
                <img
                  className="block m-auto"
                  loading="lazy"
                  src={images[2].src || ""}
                  alt={images[2].alt}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="block m-auto"
                  loading="lazy"
                  src={images[1].src || ""}
                  alt={images[1].alt}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="block m-auto w-full"
                  loading="lazy"
                  src={images[0].src || ""}
                  alt={images[0].alt}
                />
              </SwiperSlide>
            </Swiper>
          </section>

          {/* Feature Section */}
          <section className="feature">
            <Container>
              {articles.length > 0 && (
                <Row className="feature-wrapper">
                  {/* Article left */}
                  <Col lg={8}>
                    <Row>
                      <h2 className="title">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                            fill="#0196E3"
                          />
                        </svg>
                        Maqolalar
                      </h2>
                      {
                        <>
                          {articles?.slice(0, 2).map((item) => (
                            <Col
                              className="article-card max-sm:p-0"
                              lg={6}
                              key={item.id}
                            >
                              <Cards
                                type="article-big"
                                views={item.viewsCount}
                                title={item.title}
                                keywords={item.abstract}
                                category={item.category.name}
                                image={`${process.env.REACT_APP_API_URL2}${item?.image?.file_path}`}
                                id={item.slug}
                                time={item.publish_date}
                                author={item.author.full_name}
                              />
                            </Col>
                          ))}
                          {articles?.slice(2, 8).map((item) => (
                            <Col
                              className="article-card max-sm:p-0"
                              lg={6}
                              key={item.id}
                            >
                              <Cards
                                views={item.viewsCount}
                                type="article-small"
                                title={item.title}
                                keywords={item.abstract}
                                category={item.category.name}
                                image={`${process.env.REACT_APP_API_URL2}${item?.image?.file_path}`}
                                id={item.slug}
                                time={item.publish_date}
                                author={item.author.full_name}
                              />
                            </Col>
                          ))}
                        </>
                      }
                      <Link
                        className="feature-read__more mr-32 read-more max-sm:ml-0"
                        type="button"
                        to={"/articles"}
                      >
                        Ko’proq maqolalar
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                        >
                          <path
                            d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928932C12.9526 0.538408 12.3195 0.538408 11.9289 0.928932C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM0 9H19V7H0V9Z"
                            fill="#0196E3"
                          />
                        </svg>
                      </Link>
                      {/* Top Articles */}
                      <h2 className="feature-swiper__title max-sm:pl-0 title">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                            fill="#0196E3"
                          />
                        </svg>
                        Yangiliklar
                      </h2>

                      <Swiper
                        slidesPerGroup={1}
                        slidesPerView={1}
                        spaceBetween={20}
                        loop={true}
                        pagination={{
                          clickable: true,
                        }}
                        autoplay={{
                          delay: 3000,
                        }}
                        speed={700}
                        modules={[Autoplay]}
                        className="mySwiper pl-4 max-sm:pl-0"
                      >
                        {News?.data?.map((item) => (
                          <SwiperSlide
                            key={item.id}
                            className="flex flex-col md:flex-row gap-2"
                          >
                            <Link
                              to={`/news/${item.slug}`}
                              className=" w-full sm:max-h-[300px] md:w-1/2"
                            >
                              <img
                                loading="lazy"
                                className="volume-image w-full object-cover max-sm:object-contain"
                                src={`${process.env.REACT_APP_API_URL2}${item?.source?.file_path}`}
                                alt="Birinchi nashr rasmi"
                              />
                            </Link>
                            <div className=" w-full md:w-1/2 flex flex-col items-center justify-between p-1.5">
                              <div>
                                <h2 className="swiper-title w-full text-lg font-bold mb-2 text-left">
                                  {item.title}
                                </h2>
                                <p className="swiper-subtitle text-gray-600">
                                  {item.description}
                                </p>
                                <hr />
                                <p className="swiper-subtitle text-gray-600">
                                  {item.body}
                                </p>
                              </div>
                              <div className="flex items-center justify-between w-full">
                                <span className="swiper-time flex items-center">
                                  <i className="ri-calendar-2-line mr-2"></i>
                                  {dayjs(item.createdAt).format("DD.MM.YYYY")}
                                </span>
                              </div>
                            </div>
                            {/*<NewsCard data={item} />*/}
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Row>
                  </Col>
                  {/* News right */}
                  <Col lg={4}>
                    <h2 className="feature-title title">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                          fill="#0196E3"
                        />
                      </svg>
                      Yangi maqolalar
                    </h2>
                    <Row>
                      {lastArticles.length > 0 &&
                        lastArticles?.map((item) => (
                          <Col
                            className="feature-news__card max-sm:px-0"
                            lg={12}
                            key={item.id}
                          >
                            <Cards
                              type="news"
                              views={item.viewsCount}
                              title={item.title}
                              image={`${process.env.REACT_APP_API_URL2}${item?.image?.file_path}`}
                              id={item.slug}
                              time={item.publish_date}
                            />
                          </Col>
                        ))}
                      <SocialMediaIcons />
                    </Row>
                  </Col>
                </Row>
              )}
            </Container>
          </section>
          {/* Nashrlar Section */}
          <section className="info">
            <Container>
              <h3 className="info-article pt-3 title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10ZM5 7.5C6.38071 7.5 7.5 6.38071 7.5 5C7.5 3.61929 6.38071 2.5 5 2.5C3.61929 2.5 2.5 3.61929 2.5 5C2.5 6.38071 3.61929 7.5 5 7.5Z"
                    fill="#0196E3"
                  />
                </svg>
                Nashrlar
              </h3>
              <Swiper
                navigation={true}
                spaceBetween={22}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                speed={800}
                autoplay={{
                  delay: 1800,
                }}
                breakpoints={{
                  1500: {
                    slidesPerView: 4,
                  },
                  1300: {
                    slidesPerView: 3,
                  },
                  650: {
                    slidesPerView: 2,
                  },
                }}
                modules={[Navigation, Autoplay]}
                className="mySwiper pt-5 static"
              >
                {volumes.length > 0 && memoizedSlides}
              </Swiper>
            </Container>
          </section>
        </div>
      </>
      <TgAnimation />
    </>
  );
};
export default React.memo(Home);
