import React from 'react';
import {useNavigate} from "react-router";
import {Button} from "antd";
import {SwapLeftOutlined} from '@ant-design/icons'
const NavigateToback = () => {
    const navigate = useNavigate()
    return (
        <div>
            <Button icon={<SwapLeftOutlined />} onClick={() => navigate(-1)} type="primary" className="hidden max-sm:block text-white rounded-md py-1 px-3"> orqaga</Button>
        </div>
    );
};

export default NavigateToback;