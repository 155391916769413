import { Container } from "react-bootstrap";

 

import "./footer.css";
import React from "react";

const Footer = () => {
   

  return (
    <Container>
      <div className="footer-row">
        <div className="footer-end">
          <p className="copyright">
            © 2024 - Xalqaro Nordik universiteti tomonidan qo‘llab quvvatlanadi
          </p>
        </div>
      </div>
    </Container>
  );
};

export default React.memo(Footer);
