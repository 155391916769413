import { Col, Container, Row } from "react-bootstrap";
import Cards from "../cards";
import "./home-news.css";
import { useQuery } from "react-query";
import { getLastArticles } from "./lastArticle.query";
import { memo } from "react";

const HomeNews = () => {
  const { data: last } = useQuery({
    queryKey: ["article-lasts"],
    queryFn: () => getLastArticles(),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <section className="media-news">
        <Container>
          <Row className="feature-wrapper">
            <Col>
              <Row>
                {last?.length > 0 &&
                  last?.slice(0, 5).map((item) => (
                    <Col className="feature-news__card" lg={12} key={item.id}>
                      <Cards
                        views={item.viewsCount}
                        type="news"
                        title={item.title}
                        image={`${process.env.REACT_APP_API_URL2}${item?.image?.file_path}`}
                        id={item.slug}
                        time={item.createdAt}
                      />
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default memo(HomeNews);
