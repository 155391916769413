import axios from "axios";

const fetchArticles = async ({ pageParam = 1 }) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL2}/article?page=${pageParam}&limit=3`,
  );
  return response.data;
};

const scholars = [
  {
    name: "Saidahror Gʻulomov",
    title: "Iqtisodiyot fanlari doktori, professor",
    country: "O‘zbekiston",
  },
  {
    name: "Marat Raxmutlaev",
    title: "Texnika fanlari doktori, professor",
    country: "O‘zbekiston",
  },
  {
    name: "Baxtiyor Salimov",
    title: "Iqtisodiyot fanlari doktori, professor",
    country: "O‘zbekiston",
  },
  {
    name: "Olim Murtazayev",
    title: "Iqtisodiyot fanlari doktori, professor",
    country: "O‘zbekiston",
  },
  {
    name: "Shavkat Hasanov",
    title: "Iqtisodiyot fanlari doktori, professor",
    country: "O‘zbekiston",
  },
  {
    name: "Petri Juhani Raivo",
    title: "Iqtisodiyot fanlari boʻyicha falsafa doktori (PhD)",
    country: "Finlandiya",
  },
  {
    name: "Pekka Auvinen",
    title: "Iqtisodiyot fanlari boʻyicha falsafa doktori (PhD)",
    country: "Finlandiya",
  },
  {
    name: "Alisher Joʻrayev",
    title: "Iqtisodiyot fanlari boʻyicha falsafa doktori (PhD)",
    country: "O‘zbekiston",
  },
  {
    name: "Nizomiddin Urmonov",
    title: "Iqtisodiyot fanlari boʻyicha falsafa doktori (PhD), dotsent",
    country: "O‘zbekiston",
  },
  {
    name: "Mahammadsiddik Amonboyev",
    title: "Iqtisodiyot fanlari nomzodi, dotsent",
    country: "O‘zbekiston",
  },
  {
    name: "Bruno Dallago",
    title: "Iqtisodiyot fanlari boʻyicha falsafa doktori (PhD)",
    country: "Italiya",
  },
  {
    name: "Kobil Ruziyev",
    title: "Iqtisodiyot fanlari boʻyicha falsafa doktori (PhD)",
    country: "Angliya",
  },
  {
    name: "Ihtiyor Bobojonov",
    title: "Iqtisodiyot fanlari boʻyicha falsafa doktori (PhD)",
    country: "Germaniya",
  },
  {
    name: "Odiljon Abdurazzakov",
    title: "Iqtisodiyot fanlari boʻyicha falsafa doktori (PhD)",
    country: "O‘zbekiston",
  },
  {
    name: "Nuriddin Murodullayev",
    title: "Xalqaro Nordik universiteti prorektori",
    country: "O‘zbekiston",
  },
  {
    name: "Mirza Tulaev",
    title: "Iqtisodiyot fanlari boʻyicha falsafa doktori (PhD), dotsent",
    country: "O‘zbekiston",
  },
  {
    name: "Sabirov Xasan",
    title: "Iqtisodiyot fanlari boʻyicha falsafa doktori (PhD), dotsent",
    country: "O‘zbekiston",
  },
  {
    name: "Zulfiya Xamidova",
    title: "Iqtisodiyot fanlari boʻyicha falsafa doktori (PhD), dotsent",
    country: "O‘zbekiston",
  },
  {
    name: "Lochin Raxmonov",
    title: "Iqtisodiyot fanlari boʻyicha falsafa doktori (PhD)",
    country: "O‘zbekiston",
  },
  {
    name: "Musoxon Isakov",
    title: "Iqtisodiyot fanlari nomzodi, dotsent",
    country: "O‘zbekiston",
  },
  {
    name: "Maxammadmurod Shomirzayev",
    title: "Pedagogika fanlari doktori, professor",
    country: "O‘zbekiston",
  },
  {
    name: "Xusniddin Jo‘rayev",
    title: "Pedagogika fanlari doktori, professor",
    country: "O‘zbekiston",
  },
  {
    name: "Qurbonniyoz Panjiyev",
    title: "Pedagogika fanlari doktori, professor",
    country: "O‘zbekiston",
  },
  {
    name: "Dilafro‘z Miraliyeva",
    title: "Pedagogika fanlari bo‘yicha falsafa doktori (PhD)",
    country: "O‘zbekiston",
  },
  {
    name: "Nigora Lutfullayeva",
    title: "Psixologiya fanlari bo‘yicha falsafa doktori (PhD)",
    country: "O‘zbekiston",
  },
  {
    name: "Dilshod Nasriddinov",
    title: "Filologiya fanlari bo‘yicha falsafa doktori (PhD)",
    country: "O‘zbekiston",
  },
];

const accordionItems = [
  {
    id: 1,
    title: "Maqolani qabul qilish bo‘yicha qanday talablar mavjud ?",
    content: "Maqola IMRAD talablari asosida qabul qilinadi.",
  },
  {
    id: 2,
    title: "Maqola topshirish pullikmi ?",
    content: "Maqolalar bepul qabul qilinadi ?",
  },
  {
    id: 3,
    title: "Maqolani topshirish muddati qachongacha ?",
    content:
      "Jurnalining 2-soni uchun maqolalar 15-fevralga qadar qabul qilinadi.",
  },
  {
    id: 4,
    title: "Jurnal OAK ro‘yxatiga kiruvchi jurnallar qatoridan o‘rin olganmi ?",
    content:
      "Yo‘q, hali o‘tkazilmagan, jurnalning 2-sonidan keyin o‘tkazish rejamiz bor.",
  },
  {
    id: 5,
    title: "Jurnalga kimlar maqola berishi mumkin?",

    content:
      "Jurnal barcha uchun ochiq, bu bo‘yicha hech qanday cheklovlar yo‘q.",
  },
  {
    id: 6,
    title:
      "Jurnalning bitta soniga ikki yoki undan ko‘p maqola berish mumkinmi ?",
    content:
      "Bitta son uchun bitta eng dolzarb maqola berish tavsiya etiladi, agar maqolalar ikki va undan ortiq bo‘lsa keyingi sonlarda chiqarilishi mumkin.",
  },
  {
    id: 7,
    title:
      "Agar tahririyat tomonidan maqola nashr uchun tavsiya etilmasa nima qilish mumkin ?",

    content:
      "Taqrizchi tomonidan maqola mazmuni va formati maqul deb topilmasa, muallifga kamchiliklarni tuzatish uchun qayta yuboriladi. Agar maqola belgilangan muddatlarda tahririyatga qayta kelib tushmasa, jurnalning keyingi sonlarida nashr qilishga tavsiya etadi.",
  },
];

export { fetchArticles, scholars, accordionItems };
