import axios from "axios";

const getLastArticles = async () => {
  const options = {
    url: `${process.env.REACT_APP_API_URL2}/article/user/sort`,
    method: "GET",
  };
  const response = await axios.request(options);

  return response.data;
};

export { getLastArticles };
